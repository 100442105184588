import React, { useCallback, useEffect, useState } from "react";

import {
    CircularProgress, FormControl, InputLabel, MenuItem, Select,
} from "@mui/material";

import { CollectionId, IFormSchema } from "@resistance-tech/api";
import { getGlobalServices } from "../../../services/services";

interface Schemas { [id: string]: IFormSchema }

export function SchemaSelectControl({
    onChange, label = undefined, selectFirst = undefined, className = "", initialSchema = "", showInactive = true,
}
    : {
        onChange: (name: string, schema: IFormSchema | null) => void,
        label: string | undefined,
        selectFirst: boolean | undefined,
        className?: string,
        initialSchema?: string,
        showInactive?: boolean,
    }) {
    const [isLoading, setLoading] = useState(false);
    const [schemas, setSchemas] = useState<Schemas>({} as Schemas);
    const [selectedSchema, setSelectedSchema] = useState<string>(initialSchema);

    const globalServices = getGlobalServices();

    const onSelectionChanged = React.useCallback((schemaName: string) => {
        if (schemaName in schemas) {
            setSelectedSchema(schemaName);
            onChange(schemaName, schemas[schemaName]);
        }
    }, [onChange, schemas]);

    const isAvailable = useCallback((schema: IFormSchema) => showInactive || schema.status !== "inactive", [showInactive]);

    useEffect(() => {
        if (isLoading || Object.keys(schemas).length > 0) {
            return;
        }
        setLoading(true);
        globalServices?.dataService
            .getAllDocuments<IFormSchema>(CollectionId.SheetsSchemas)
            .then(async (schemaObject) => {
                setSchemas(schemaObject);
                if (selectFirst) {
                    const availableSchemas = Object.entries(schemaObject)
                        .filter(([_, value]) => isAvailable(value))
                        .map(([key]) => key);
                    onSelectionChanged(availableSchemas[0]);
                }
                setLoading(false);
            });
    }, [setLoading, setSchemas, selectFirst, onSelectionChanged, globalServices, isLoading, schemas, isAvailable]);

    useEffect(() => {
        if (!selectFirst) {
            onSelectionChanged(initialSchema);
        }
    }, [initialSchema, selectFirst, onSelectionChanged]);

    if (isLoading) {
        return <CircularProgress />;
    }

    return (
        <FormControl className={className}>
            {label && (<InputLabel>Select Schema</InputLabel>)}

            <Select
                value={selectedSchema}
                onChange={(e) => onSelectionChanged(e.target.value as string)}
            >
                {Object.entries(schemas)
                    .filter(([_, schema]) => isAvailable(schema))
                    .map(
                        ([schemaName]) => (<MenuItem key={schemaName} value={schemaName}>{schemaName}</MenuItem>),
                    )}
            </Select>
        </FormControl>
    );
}
