import {
    IActivismEvent, IActivismUserProfile,
} from "@resistance-tech/api";

export enum ActivismState {
    ACTIVISM_SET_EVENTS,
    ACTIVISM_ADD_EVENTS,
    ACTIVISM_REMOVE_EVENTS,
    ACTIVISM_SET_USER_PROFILES,
    ACTIVISM_SET_IS_LOADING,
    ACTIVISM_OPEN_EVENT_VIEWER,
    ACTIVISM_CLOSE_EVENT_VIEWER,
    ACTIVISM_OPEN_EVENT_CREATOR,
    ACTIVISM_CLOSE_EVENT_CREATOR,
    ACTIVISM_OPEN_EVENT_EDITOR,
    ACTIVISM_CLOSE_EVENT_EDITOR,
    ACTIVISM_SET_FILTER,
}

export interface ActivismSetEventsAction {
    type: typeof ActivismState.ACTIVISM_SET_EVENTS;
    payload: {
        events: IActivismEvent[]
    };
}

export interface ActivismAddEventsAction {
    type: typeof ActivismState.ACTIVISM_ADD_EVENTS;
    payload: {
        events: IActivismEvent[]
    };
}

export interface ActivismRemoveEventsAction {
    type: typeof ActivismState.ACTIVISM_REMOVE_EVENTS;
    payload: {
        eventIds: string[]
    };
}

export interface ActivismSetUserProfiles {
    type: typeof ActivismState.ACTIVISM_SET_USER_PROFILES;
    payload: {
        userProfiles: IActivismUserProfile[];
        organiserUserProfiles: IActivismUserProfile[];
    };
}

export interface ActivismSetIsLoading {
    type: typeof ActivismState.ACTIVISM_SET_IS_LOADING;
    payload: { isLoading: boolean };
}

export interface ActivismOpenEventViewer {
    type: typeof ActivismState.ACTIVISM_OPEN_EVENT_VIEWER;
    payload: { eventId: string };
}

export interface ActivismCloseEventViewer {
    type: typeof ActivismState.ACTIVISM_CLOSE_EVENT_VIEWER;
}

export interface ActivismOpenEventCreator {
    type: typeof ActivismState.ACTIVISM_OPEN_EVENT_CREATOR;
}

export interface ActivismCloseEventCreator {
    type: typeof ActivismState.ACTIVISM_CLOSE_EVENT_CREATOR;
}

export interface ActivismOpenEventEditor {
    type: typeof ActivismState.ACTIVISM_OPEN_EVENT_EDITOR;
    payload: { eventId: string };
}

export interface ActivismCloseEventEditor {
    type: typeof ActivismState.ACTIVISM_CLOSE_EVENT_EDITOR;
}

export interface ActivismSetFilter {
    type: typeof ActivismState.ACTIVISM_SET_FILTER;
    payload: { filter: IFilter };
}

export type ActionType = ActivismSetEventsAction
    | ActivismAddEventsAction
    | ActivismRemoveEventsAction
    | ActivismSetUserProfiles
    | ActivismSetIsLoading
    | ActivismOpenEventViewer
    | ActivismCloseEventViewer
    | ActivismOpenEventCreator
    | ActivismCloseEventCreator
    | ActivismOpenEventEditor
    | ActivismCloseEventEditor
    | ActivismSetFilter;

export interface IFilter {
    searchTerm: string;
    onlyParticipating: boolean;
    onlyCoordinating: boolean;
    onlyOrganising: boolean;
    distanceOriginPoint: { lat: number; lng: number } | undefined;
    distanceKms: number;
}

export interface IComponentState {
    events: IActivismEvent[];
    userProfiles: IActivismUserProfile[];
    organiserUserProfiles: IActivismUserProfile[];
    isLoading: boolean;
    /** If defined, the event viewer is opened */
    openEventId: string | undefined;
    /** If true, the event creator is opened */
    isCreateEventOpen: boolean;
    /** If defined, the event editor is opened */
    editedEventId: string | undefined;
    filter: IFilter;
}
