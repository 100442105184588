import React, { useEffect, useRef } from "react";

interface IChartSlice {
    caption: string;
    color: string;
    size: (props: any) => number;
}

const chartSlices: IChartSlice[] = [
    {
        caption: "completed",
        color: "#ff0000",
        size: (props: any) => props.completed,
    },
    {
        caption: "currently assigned",
        color: "#00ff00",
        size: (props: any) => props.currentlyAssigned,
    },
    {
        caption: "remains",
        color: "#0000ff",
        size: (props: any) => props.remains,
    },
];

function getCenter(ctx: any) {
    return {
        x: ctx.canvas.clientWidth / 2,
        y: ctx.canvas.height / 2,
    };
}

export const PieChart = (props: any) => {
    const {
        width, height,
    } = props;
    const canvasRef = useRef(null);

    useEffect(() => {
        function drawChartSlice(
            ctx: any,
            centerX: number,
            centerY: number,
            radius: number,
            start: number,
            end: number,
            value: string,
            color: string,
        ) {
            // paint the chart slice
            ctx.fillStyle = color;
            ctx.strokeStyle = "#eeeeee";
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(centerX, centerY);
            ctx.arc(centerX, centerY, radius, start * 2 * Math.PI, end * 2 * Math.PI);
            ctx.closePath();
            ctx.fill();
            ctx.stroke();

            // painting the size next to the slice
            const center = getCenter(ctx);
            const textAngle = (start + end) * Math.PI;
            const textX = center.x + Math.cos(textAngle) * (radius + 12);
            const textY = center.y + Math.sin(textAngle) * (radius + 12);

            ctx.fillStyle = "black";
            ctx.strokeStyle = "lightgray";
            ctx.font = "18px Arial";
            ctx.lineWidth = 0.1;

            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillText(value, textX, textY);
            ctx.strokeText(value, textX, textY);
        }

        function drawLegend(ctx: any, positionX: number, positionY: number, text: string, color: string) {
            ctx.font = "18px Arial";
            ctx.lineWidth = 0.1;

            ctx.textAlign = "left";
            ctx.textBaseline = "top";
            ctx.fillStyle = color;
            ctx.strokeStyle = "lightgray";
            ctx.fillText("●", positionX, positionY);
            ctx.strokeText("●", positionX, positionY);

            ctx.fillStyle = "black";

            ctx.fillText(text, positionX + 15, positionY);
            ctx.strokeText(text, positionX + 15, positionY);
        }

        const drawChart = (ctx: any) => {
            if (props.numberOfSheets === 0) {
                return;
            }

            const center = getCenter(ctx);

            const border = 75;
            const radius = Math.min(center.x, center.y) - border;

            let sliceStart = 0;
            chartSlices.forEach((chartSlice, idx) => {
                const chartSliceSize = chartSlice.size(props);
                if (!chartSliceSize) {
                    return;
                }

                const normalizedChartSliceSize = chartSliceSize / props.numberOfSheets;
                if (normalizedChartSliceSize < Number.EPSILON) {
                    return;
                }

                drawChartSlice(
                    ctx,
                    center.x,
                    center.y,
                    radius,
                    sliceStart,
                    sliceStart + normalizedChartSliceSize,
                    chartSliceSize.toString(),
                    chartSlice.color,
                );
                sliceStart += normalizedChartSliceSize;

                drawLegend(ctx, 0, idx * 20 + 10, chartSlice.caption, chartSlice.color);
            });
        };

        const canvas: any = canvasRef.current;
        if (!canvas) {
            return;
        }
        const context = canvas.getContext("2d");

        drawChart(context);
    }, [props]);

    return <canvas ref={canvasRef} width={width} height={height} />;
};
