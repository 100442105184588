/* eslint-disable react/no-array-index-key */
import React, { Dispatch, useMemo } from "react";
import { styled } from "@mui/material/styles";
import {
    IFieldType, IFormSchema, IFormSubmission, ISignatureData, ISignatureDataWithImportFailureReason,
} from "@resistance-tech/api";
import { InputValue } from "./inputFields/input.types";
import { AddressInput } from "./inputFields/addressInputField";
import { CommentInput } from "./inputFields/commentInput";
import { NameInput } from "./inputFields/nameInputField";
import { ConsentInput } from "./inputFields/consentInputField";
import { EmailInput } from "./inputFields/emailInputField";
import { PhoneInput } from "./inputFields/phoneInputField";
import { ActionType, SheetState } from "./sheetDigitalization/sheetDigitalization.types";
import { SinglePostCodeInput } from "./inputFields/singlePostCodeField";
import { ImportErrorReasonMarker } from "./importErrorReasonMarker";

const consentStyle = {
    flexCss: "0 0 6.5em",
    flexJustifyContent: "center",
};

const COLUMNS = {
    name: {
        title: "Név",
        flexCss: "2 0 20em",
        flexJustifyContent: "flex-start",
        renderComponent: NameInput,
    },
    address: {
        title: "Lakcím",
        flexCss: "3 0 35em",
        flexJustifyContent: "flex-start",
        renderComponent: AddressInput,
    },
    singlePostCode: {
        title: "Irányí- \n tószám",
        flexCss: "0 0 6em",
        flexJustifyContent: "flex-start",
        renderComponent: SinglePostCodeInput,
    },
    phoneNumber: {
        title: "Telefon",
        flexCss: "0 0 11em",
        flexJustifyContent: "flex-start",
        renderComponent: PhoneInput,
    },
    emailAddress: {
        title: "Email",
        flexCss: "0 0 20em",
        flexJustifyContent: "flex-start",
        renderComponent: EmailInput,
    },
    consentToGoal: {
        title: "Kampány",
        ...consentStyle,
        renderComponent: ConsentInput,
    },
    consentToEmail: {
        title: "Email",
        ...consentStyle,
        renderComponent: ConsentInput,
    },
    consentToPhone: {
        title: "Telefon",
        ...consentStyle,
        renderComponent: ConsentInput,
    },
    consentToContact: {
        title: "Kapcsolat-\ntartás",
        ...consentStyle,
        renderComponent: ConsentInput,
    },
    comment: {
        title: "Megjegyzés",
        ...consentStyle,
        renderComponent: CommentInput,
    },
};

export function SheetEditTable({ schema, submission, dispatch }
    : {schema: IFormSchema, submission: IFormSubmission<ISignatureData>, dispatch: Dispatch<ActionType>}) {
    const invalidFields = schema.fields.filter((field) => !Object.keys(COLUMNS).includes(field));
    if (invalidFields.length > 0) {
        return (
            <div>
                Schema:
                {" "}
                {schema.name}
                {" "}
                contains invalid fields!
            </div>
        );
    }

    return (
        <SheetTable>
            <TableHeader fields={schema.fields} />
            <TableEdit fields={schema.fields} submission={submission} dispatch={dispatch} />
        </SheetTable>
    );
}

function TableHeader({ fields } : {fields: IFieldType[]}) {
    return (
        <SheetHeaderRow>
            {fields.map((field) => (
                <SheetHeaderCell
                    key={field}
                    flexCss={COLUMNS[field].flexCss || "0 0 1em"}
                    flexJustifyContent={COLUMNS[field].flexJustifyContent}
                >
                    {COLUMNS[field].title}
                </SheetHeaderCell>
            ))}
            <SheetHeaderCell
                key="import-error-reason"
                flexCss={consentStyle.flexCss}
                flexJustifyContent={consentStyle.flexJustifyContent}
            >
                Hiba
            </SheetHeaderCell>
        </SheetHeaderRow>
    );
}

function TableRowCell({
    idx, field, signature, dispatch,
} : {idx: number, field: IFieldType, signature: ISignatureData, dispatch: Dispatch<ActionType>}) {
    const dispatcher = React.useCallback((value: InputValue) => {
        dispatch({
            type: SheetState.SIGNATURE_CHANGED,
            payload: { index: idx, field, value },
        });
    }, [dispatch, field, idx]);
    const component = COLUMNS[field].renderComponent({
        initialValue: signature[field] as any, // TODO: fix typing
        inputChangeHandler: dispatcher,
    });
    return useMemo(() => (
        <SheetCell
            flexCss={COLUMNS[field].flexCss || "0 0 1em"}
            flexJustifyContent={COLUMNS[field].flexJustifyContent}
        >
            {component}
        </SheetCell>
    ), [field, component]);
}

function TableEdit({ fields, submission, dispatch }
    : {
        fields: IFieldType[],
        submission: IFormSubmission<ISignatureDataWithImportFailureReason>,
        dispatch: Dispatch<ActionType>
    }) {
    return (
        <SheetTableEdit>
            {submission.data.map((_, idx) => (
                <SheetRow key={idx}>
                    {fields.map((field) => (
                        <TableRowCell
                            key={field}
                            idx={idx}
                            dispatch={dispatch}
                            signature={submission.data[idx]}
                            field={field}
                        />
                    ))}
                    <SheetCell
                        key="import-error-reason"
                        flexCss={consentStyle.flexCss}
                        flexJustifyContent={consentStyle.flexJustifyContent}
                    >
                        <ImportErrorReasonMarker value={submission.data[idx].reason} />
                    </SheetCell>
                </SheetRow>
            ))}
        </SheetTableEdit>
    );
}

const SheetTable = styled("div")`
    display: flex;
    align-self: stretch;
    flex-flow: column nowrap;
    justify-content: space-between;
`;

const SheetTableEdit = styled("div")`
    flex-grow: 1;
    display: block;
`;

const SheetHeaderRow = styled("div")`
    background: white;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid;
    color: black;
    position: sticky;
    top: 0;
    z-index: 111;
    padding-bottom: 1em;
`;
const SheetRow = styled("div")`
    display: flex;
    flex-wrap: wrap;
    border: 1px solid;

    &:nth-child(odd) {
        background-color: #FFFFFF;
        color: black;
    }
    
    &:nth-child(even) {
        background-color: #DDDDDD;
        color: black;        
    }    
`;

const Cell = styled("div")<{flexCss: string, flexJustifyContent: string}>`
    flex: ${(props) => props.flexCss};
    padding-right: 20px;
    display: flex;
    justify-content: ${(props) => props.flexJustifyContent};;
`;

const SheetHeaderCell = styled(Cell)`
    font-weight: bold;
    vertical-align: bottom;
    overflow: hidden;
`;

const SheetCell = styled(Cell)`
`;
