import {
    IFirestoreSurvey,
    IFirestoreSurveyMultipleResponse,
    IFirestoreSurveyResponse,
    IFirestoreSurveySingleResponse,
    SurveyType,
} from "@resistance-tech/api";
import * as React from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

interface IProps {
    survey: IFirestoreSurvey,
    responses: undefined | IFirestoreSurveyResponse[],
}

export function SurveyResponseBreakdownView({
    survey,
    responses,
}: IProps) {
    // Show breakdown for scale options
    const surveyScale = (scaleResponses: IFirestoreSurveySingleResponse[]): any => {
        if (survey.options.type !== "scale") {
            return null;
        }
        const responseValues = scaleResponses.map((response) => response.value);

        const calculateMedian = (arr: number[]) => {
            const mid = Math.floor(arr.length / 2);
            const nums = [...arr].sort((a, b) => a - b);
            return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
        };

        const median = calculateMedian(responseValues);
        const average = responseValues.reduce((v1, v2) => v1 + v2) / responseValues.length;
        return (
            <div>
                <Element>
                    <b>Medián:</b>
                    {median}
                </Element>
                <Element>
                    <b>Átlag:</b>
                    {average}
                </Element>
            </div>
        );
    };

    // Show breakdown for choice options
    const surveyChoice = (choiceResponses: IFirestoreSurveyMultipleResponse[]): any => {
        const options: { key: number, label: string, count: number }[] = survey.options.type === "choice"
            ? survey.options.values.map((v) => ({
                label: v.label,
                count: 0,
                key: v.key,
            }))
            : [];

        choiceResponses.forEach((response) => {
            response.values.forEach((value) => {
                const option = options.find((opt) => opt.key === value);
                if (option === undefined) {
                    return;
                }
                option.count += 1;
            });
        });

        options.sort((o1, o2) => (o1.count < o2.count ? 1 : -1));
        return (
            <div>
                {options.map((option) => (
                    <Element key={option.key}>
                        <b>
                            {`${option.label}:`}
                        </b>
                        {option.count}
                    </Element>
                ))}
            </div>
        );
    };

    const surveyResponses = (): any => {
        if (responses === undefined) {
            return null;
        }

        switch (survey.type) {
        case SurveyType.Scale:
            return surveyScale(responses.map((r) => r.value as IFirestoreSurveySingleResponse));
        case SurveyType.Choice:
            return surveyChoice(responses.map((r) => r.value as IFirestoreSurveyMultipleResponse));
        case SurveyType.Text:
            return null;
        default:
            return null;
        }
    };

    return (
        <div>
            <Title variant="h6" gutterBottom>
                Válaszok
                (
                {responses?.length}
                )
            </Title>
            {(responses === undefined || responses.length === 0)
            && <Description>Még nem érkezett válasz...</Description>}
            {(responses !== undefined && responses.length > 0) && (
                surveyResponses()
            )}
        </div>
    );
}

const Title = styled(Typography)`
  font-weight: 500;

  & > a {
    color: black;
  }
`;

const Element = styled(Typography)`
  display: inline-block;
  background-color: #8e6fce;
  color: white;
  border-radius: 7px;
  box-shadow: 3px 3px 3px #555;
  margin: 10px;
  padding: 5px 8px;

`;

const Description = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
` as typeof Typography;
