import { ISheetCounters, assertNever } from "@resistance-tech/api";
import { ActionType, ISheetStatisticsState, SheetStatisticsAction } from "./sheetStatistics.type";

export const DEFAULT_STATE: ISheetStatisticsState = {
    schemaIdList: [],
    sheetCounters: {},
    errorMessage: undefined,
    requestInProgress: false,
};

export const reducer = (originalState: ISheetStatisticsState, action: ActionType): ISheetStatisticsState => {
    switch (action.type) {
    case SheetStatisticsAction.SHEET_SCHEMA_LIST_REQUESTED:
        return {
            ...originalState,
            requestInProgress: true,
            schemaIdList: [],
        };
    case SheetStatisticsAction.SHEET_SCHEMA_LIST_RESPONSE:
        return {
            ...originalState,
            requestInProgress: false,
            schemaIdList: action.payload,
        };
    case SheetStatisticsAction.SHEET_STATISTICS_REQUESTED:
        return {
            ...originalState,
            requestInProgress: true,
        };
    case SheetStatisticsAction.SHEET_STATISTICS_RESPONSE: {
        const newCounter: { [schemaId: string]: ISheetCounters } = {};
        newCounter[action.payload.schemaId] = action.payload.counters;
        return {
            ...originalState,
            sheetCounters: {
                ...originalState.sheetCounters,
                ...newCounter,
            },
            requestInProgress: false,
        };
    }
    case SheetStatisticsAction.SHEET_RECONCILE_STATISTICS_REQUESTED:
        return {
            ...originalState,
            requestInProgress: true,
        };
    case SheetStatisticsAction.SHEET_RECONCILE_STATISTICS_RESPONSE: {
        const newCounter: { [schemaId: string]: ISheetCounters } = {};
        newCounter[action.payload.schemaId] = action.payload.counters;

        return {
            ...originalState,
            sheetCounters: {
                ...originalState.sheetCounters,
                ...newCounter,
            },
            requestInProgress: false,
        };
    }
    default:
        assertNever(action, "Unexpected action type");
        return originalState;
    }
};
