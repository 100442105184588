import type { Location, NavigateFunction } from "react-router";
import * as queryString from "query-string";

export enum Page {
    Home = "home",
    SignIn = "signin",
    TermsOfService = "terms-of-service",
    PrivacyPolicy = "privacy-policy",
    Admin = "admin",
    JobsOverview = "jobs-overview",
    MobilePosts = "mobilePosts",
    MobileSurveys = "mobile-surveys",
    MobileEvents = "mobile-events",
    MobileTips = "mobile-tips",
    SheetTyping = "sheet-typing",
    SheetUpload = "sheet-upload",
    SheetStatistics = "sheet-statistics",
    SheetSchemas = "sheet-schemas",
    ActivismBooking = "activism-booking",
    ActivismResults = "activism-results",
}

interface ISignInRouteQueryParams {
    redirectUrl: string | undefined;
}

const getNavUrl = {
    [Page.Home]: () => "/",
    [Page.SignIn]: (redirectUrl?: string) => `/signin${redirectUrl === undefined ? "" : `?redirectUrl=${redirectUrl}`}`,
    [Page.TermsOfService]: () => "/terms-of-service",
    [Page.PrivacyPolicy]: () => "/privacy-policy",
    [Page.Admin]: () => "/admin",
    [Page.JobsOverview]: () => "/jobs",
    [Page.MobilePosts]: () => "/mobile-posts",
    [Page.MobileSurveys]: () => "/mobile-surveys",
    [Page.MobileEvents]: () => "/mobile-events",
    [Page.MobileTips]: () => "/mobile-tips",
    [Page.SheetTyping]: () => "/sheet-typing",
    [Page.SheetUpload]: () => "/sheet-upload",
    [Page.SheetStatistics]: () => "/sheet-statistics",
    [Page.SheetSchemas]: () => "/sheet-schemas",
    [Page.ActivismBooking]: () => "/activism-booking",
    [Page.ActivismResults]: () => "/activism-results",
};

const getNavUrlTemplate: { [page in Page]: string } = {
    [Page.Home]: getNavUrl[Page.Home](),
    [Page.SignIn]: getNavUrl[Page.SignIn](),
    [Page.TermsOfService]: getNavUrl[Page.TermsOfService](),
    [Page.PrivacyPolicy]: getNavUrl[Page.PrivacyPolicy](),
    [Page.MobilePosts]: getNavUrl[Page.MobilePosts](),
    [Page.MobileSurveys]: getNavUrl[Page.MobileSurveys](),
    [Page.MobileEvents]: getNavUrl[Page.MobileEvents](),
    [Page.JobsOverview]: getNavUrl[Page.JobsOverview](),
    [Page.SheetTyping]: getNavUrl[Page.SheetTyping](),
    [Page.SheetUpload]: getNavUrl[Page.SheetUpload](),
    [Page.SheetStatistics]: getNavUrl[Page.SheetStatistics](),
    [Page.Admin]: getNavUrl[Page.Admin](),
    [Page.SheetSchemas]: getNavUrl[Page.SheetSchemas](),
    [Page.MobileTips]: getNavUrl[Page.MobileTips](),
    [Page.ActivismBooking]: getNavUrl[Page.ActivismBooking](),
    [Page.ActivismResults]: getNavUrl[Page.ActivismResults](),
};

const getNavUrlQueryParams = {
    [Page.SignIn]: (value: string) => (
        queryString.parse(value) as unknown
    ) as ISignInRouteQueryParams,
};

const singInAndReturn = (navigate: NavigateFunction, location: Location) => {
    const currentPath = location.pathname;
    navigate(getNavUrl[Page.SignIn](currentPath));
};

const pageTitleBase = "Resistance";
const pageTitleEnding = "";

function getPageTitle(title?: string) {
    const titlePrefix = title === undefined ? "" : `${title} | `;
    return `${titlePrefix}${pageTitleBase}${pageTitleEnding}`;
}

const getNavUrlSimpleTitle: { [page in Page]: string } = {
    [Page.Home]: getPageTitle(),
    [Page.SignIn]: getPageTitle("Sign in"),
    [Page.TermsOfService]: getPageTitle("Terms of Service"),
    [Page.PrivacyPolicy]: getPageTitle("Privacy Policy"),
    [Page.Admin]: getPageTitle("Admin"),
    [Page.JobsOverview]: getPageTitle("Jobs"),
    [Page.MobilePosts]: getPageTitle("Mobile - Posts"),
    [Page.MobileSurveys]: getPageTitle("Mobile - Surveys"),
    [Page.MobileEvents]: getPageTitle("Mobile - Events"),
    [Page.MobileTips]: getPageTitle("Mobile - Tips"),
    [Page.SheetTyping]: getPageTitle("Adatbevitel - gépelés"),
    [Page.SheetUpload]: getPageTitle("Adatbevitel - feltöltés"),
    [Page.SheetStatistics]: getPageTitle("Adatbevitel - statisztika"),
    [Page.SheetSchemas]: getPageTitle("Adatbevitel - kampányok"),
    [Page.ActivismBooking]: getPageTitle("Aktivizmus - foglalás"),
    [Page.ActivismResults]: getPageTitle("Aktivizmus - eredmények"),
};

export const NavUtils = {
    getNavUrl,
    getNavUrlTemplate,
    getNavUrlQueryParams,
    singInAndReturn,
    getNavUrlSimpleTitle,
};
