import { FirebaseApp } from "firebase/app";
import {
    IFirebaseFunctionManageSheetDocumentRequest,
    IFirebaseFunctionManageSheetDocumentResponse,
    IFunctionsActivismAddParticipantRequest,
    IFunctionsActivismAddParticipantResponse,
    IFunctionsActivismCreateEventsRequest,
    IFunctionsActivismCreateEventsResponse,
    IFunctionsActivismDeleteEventsRequest,
    IFunctionsActivismDeleteEventsResponse,
    IFunctionsActivismGetEventsRequest,
    IFunctionsActivismGetEventsResponse,
    IFunctionsActivismGetGeneralStatsRequest,
    IFunctionsActivismGetGeneralStatsResponse,
    IFunctionsActivismGetOrganiserUserProfilesRequest,
    IFunctionsActivismGetOrganiserUserProfilesResponse,
    IFunctionsActivismGetUserProfilesRequest,
    IFunctionsActivismGetUserProfilesResponse,
    IFunctionsActivismRemoveParticipantRequest,
    IFunctionsActivismRemoveParticipantResponse,
    IFunctionsActivismUpdateEventsRequest,
    IFunctionsActivismUpdateEventsResponse,
    IFunctionsActivismUpdateUserProfileRequest,
    IFunctionsActivismUpdateUserProfileResponse,
    IImportSubmissionsRequest,
    IImportSubmissionsResponse,
    IManageUserRolesRequest,
    IManageUserRolesResponse,
    ISchemaUpdateRequest,
    ISheetCounterHttpRequest,
    ISheetCounters,
    ISheetPreferencesChangeRequest,
    IUserRolesResponse,
} from "@resistance-tech/api";
import { Functions, getFunctions, httpsCallable } from "firebase/functions";

export class FunctionsService {
    private functions: Functions;

    constructor(firebaseApp: FirebaseApp) {
        this.functions = getFunctions(firebaseApp, "europe-west1");
    }

    private async call<T>(functionName: string, request?: unknown) : Promise<T> {
        const remoteFunction = httpsCallable(this.functions, functionName);
        const response = await remoteFunction(request);
        return response.data as T;
    }

    public manageSheetDocument = async (request: IFirebaseFunctionManageSheetDocumentRequest = {
        actionWithCurrent: undefined,
        assignNew: true,
    }) => this.call<IFirebaseFunctionManageSheetDocumentResponse>("manageSheetDocument", request);

    public handleGetSheetStatistics = async (request: ISheetCounterHttpRequest = { schemaId: "" }) => this.call<ISheetCounters>("handleGetSheetStatistics", request);

    public handleReconcileSheetStatistics = async (request: ISheetCounterHttpRequest = { schemaId: "" }) => this.call<ISheetCounters>("handleReconcileSheetStatistics", request);

    public importSubmissions = async (request?: IImportSubmissionsRequest) => this.call<IImportSubmissionsResponse>("importSubmissions", request);

    public getUserRoles = async () : Promise<IUserRolesResponse> => this.call<IUserRolesResponse>("getUserRoles");

    public updateUserRoles = async (request: IManageUserRolesRequest[]) => this.call<IManageUserRolesResponse>("updateUserRoles", request);

    public saveSheetPreferences = async (request: ISheetPreferencesChangeRequest) => this.call<void>("saveSheetPreferences", request);

    public updateSchema = async (request: ISchemaUpdateRequest) => this.call<void>("updateSchema", request);

    public registerUsers = async (request: IManageUserRolesRequest[]) => this.call<void>("registerUsers", request);

    // Activism functions

    public activismGetEvents = async (request: IFunctionsActivismGetEventsRequest) => this.call<IFunctionsActivismGetEventsResponse>("activism-getEvents", request);

    public activismCreateEvents = async (request: IFunctionsActivismCreateEventsRequest) => this.call<IFunctionsActivismCreateEventsResponse>("activism-createEvents", request);

    public activismDeleteEvents = async (request: IFunctionsActivismDeleteEventsRequest) => this.call<IFunctionsActivismDeleteEventsResponse>("activism-deleteEvents", request);

    public activismUpdateEvents = async (request: IFunctionsActivismUpdateEventsRequest) => this.call<IFunctionsActivismUpdateEventsResponse>("activism-updateEvents", request);

    public activismAddParticipant = async (request: IFunctionsActivismAddParticipantRequest) => this.call<IFunctionsActivismAddParticipantResponse>("activism-addParticipant", request);

    public activismRemoveParticipant = async (request: IFunctionsActivismRemoveParticipantRequest) => this.call<IFunctionsActivismRemoveParticipantResponse>("activism-removeParticipant", request);

    public activismUpdateUserProfile = async (request: IFunctionsActivismUpdateUserProfileRequest) => this.call<IFunctionsActivismUpdateUserProfileResponse>("activism-updateUserProfile", request);

    public activismGetUserProfiles = async (request: IFunctionsActivismGetUserProfilesRequest) => this.call<IFunctionsActivismGetUserProfilesResponse>("activism-getUserProfiles", request);

    public activismGetOrganiserUserProfiles = async (request: IFunctionsActivismGetOrganiserUserProfilesRequest) => this.call<IFunctionsActivismGetOrganiserUserProfilesResponse>("activism-getOrganiserUserProfiles", request);

    public activismGetGeneralStats = async (request: IFunctionsActivismGetGeneralStatsRequest) => this.call<IFunctionsActivismGetGeneralStatsResponse>("activism-getGeneralStats", request);
}
