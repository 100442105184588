import React, { useState } from "react";
import { TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { InputChangeHandler } from "./input.types";

const useStyles = makeStyles(() => ({
    container: {
        width: "100%",
    },
}));

export function SinglePostCodeInput({ initialValue = "", inputChangeHandler = () => {} }
    : {initialValue? : string, inputChangeHandler?: InputChangeHandler<string>}) {
    const [value, setValue] = useState(initialValue);

    const classes = useStyles();
    return (
        <TextField
            className={classes.container}
            autoComplete="off"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onBlur={() => {
                inputChangeHandler(value);
            }}
        />
    );
}
