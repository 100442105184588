import * as React from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Typography,
} from "@mui/material";
import { Delete, Edit, OpenInNew } from "@mui/icons-material";
import { IFirestorePost } from "@resistance-tech/api";
import { selectSenders } from "../../store/selectors";
import { IAppState } from "../../store/state";

interface IProps {
    post: IFirestorePost;
    onEditButtonClick: () => void;
    onDeleteButtonClick: () => void;
}

export function PostView({
    post, onEditButtonClick, onDeleteButtonClick,
}: IProps) {
    const senders = useSelector((state: IAppState) => Object.entries(selectSenders(state)));

    const {
        title, description, url, createdTimestamp,
    } = post;
    const createdDate = createdTimestamp.toDate();

    const senderData = senders
        .filter(([senderId]) => senderId === post.senderId)[0];
    const sender = senderData ? senderData[1] : undefined;

    return (
        <PostCard>
            <CardContent>
                <Header>
                    <Title variant="h6" gutterBottom>
                        <a href={url} target="_blank" rel="noopener noreferrer">
                            {title}
                        </a>
                    </Title>

                    {sender ? (
                        <SenderContainer color="textSecondary" gutterBottom>
                            -
                            {" "}

                            <SenderImageContainer>
                                <SenderImage src={sender.imageUrl} alt="sender" />
                            </SenderImageContainer>

                            {sender.name}
                        </SenderContainer>
                    ) : null}
                </Header>

                <Date variant="subtitle1" color="textSecondary" gutterBottom>
                    {createdDate.toLocaleString()}
                </Date>

                <Typography component="p">
                    {description ?? "-"}
                </Typography>
            </CardContent>

            <PostCardActions>
                <Button onClick={() => window.open(url)} startIcon={<OpenInNew />}>
                    Open Link
                </Button>

                <Button onClick={onEditButtonClick} startIcon={<Edit />}>
                    Edit
                </Button>

                <Button onClick={onDeleteButtonClick} startIcon={<Delete />}>
                    Delete
                </Button>
            </PostCardActions>
        </PostCard>
    );
}

// Styles
const PostCard = styled(Card)`
    margin: 20px 0 0 0;
    padding: ${({ theme }) => theme.spacing(2, 4)};
`;

const PostCardActions = styled(CardActions)`
    justify-content: flex-end;

    & > * {
        padding-left: ${({ theme }) => theme.spacing(5)};
        padding-right: ${({ theme }) => theme.spacing(5)};

        :not(:first-of-type) {
            margin-left: ${({ theme }) => theme.spacing(3)};
        }
    }
`;

const Header = styled("div")`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Title = styled(Typography)`
    font-weight: 500;
    
    & > a {
        color: black;
    }
`;

const Date = styled(Typography)`
    margin-bottom: ${({ theme }) => theme.spacing(5)};
`;

const SenderContainer = styled(Typography)`
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
`;

const SenderImageContainer = styled("span")`
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 0.5rem;
`;

const SenderImage = styled("img")`
    width: 1.5rem;
    border-radius: 50%;
`;
