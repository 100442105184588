import { serverTimestamp } from "firebase/firestore";
import * as React from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from "@mui/material";
import { DefaultEditor } from "react-simple-wysiwyg";
import { getGlobalServices } from "../../services/services";
import { selectCurrentUser, selectTip } from "../../store/selectors";
import { IAppState } from "../../store/state";
import { EditorRequest } from "../../types";

interface IProps {
    request: EditorRequest | undefined;
    onClose: () => void;
}

export function TipEditor({ request, onClose }: IProps) {
    const isOpen = request !== undefined;
    const tipId = request?.type === "update" ? request?.itemId : undefined;

    // Redux state
    const user = useSelector((state: IAppState) => selectCurrentUser(state));

    const currentTip = useSelector((state: IAppState) => selectTip(state, tipId ?? ""));

    // Local state
    const [title, setTitle] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [imageUrl, setImageUrl] = React.useState("");

    const [errors, setErrors] = React.useState<{ [property: string]: string | undefined }>({});

    React.useEffect(() => {
        if (isOpen && currentTip) {
            if (currentTip.title) {
                setTitle(currentTip.title);
            }

            if (currentTip.description) {
                setDescription(currentTip.description);
            }

            if (currentTip.imageUrl) {
                setImageUrl(currentTip.imageUrl);
            }
        }
    }, [isOpen, currentTip]);

    const handleClose = () => {
        onClose();

        setTitle("");
        setDescription("");
        setImageUrl("");
        setErrors({});
    };

    const handleDialogClose = (_event: {}, reason: "backdropClick" | "escapeKeyDown") => {
        if (reason === "backdropClick") {
            return;
        }
        handleClose();
    };

    const handleSaveClick = async () => {
        const globalServices = getGlobalServices();
        if (globalServices === undefined) {
            return;
        }

        const newErrors = {
            title: !title ? "Please give a title for your tip" : undefined,
            description: !description ? "Please give a description for your tip" : undefined,
        };
        setErrors(newErrors);

        if (Object.values(newErrors).some((error) => error)) {
            return;
        }

        handleClose();

        const tipProps = {
            title,
            description,
            imageUrl,
        };

        const { dataService } = globalServices;
        if (request?.type === "update") {
            await dataService.updateTip(request.itemId, tipProps);
        } else if (user !== undefined) {
            await dataService.createTip({
                ...tipProps,
                createdTimestamp: serverTimestamp(),
            });
        }
    };

    const dialogTitle = currentTip ? "Edit tip" : "Create tip";

    const onEditorChange = (event: any) => {
        setDescription(event.target.value);
    };

    return (
        <EditorDialog
            open={isOpen}
            onClose={handleDialogClose}
            aria-labelledby="form-dialog-title"
            classes={{ paper: "paper" }}
        >
            <EditorDialogtitle id="form-dialog-title">
                {dialogTitle}
            </EditorDialogtitle>

            <EditorDialogContent>
                <TextField
                    variant="outlined"
                    value={title}
                    onChange={({ target }) => setTitle(target.value)}
                    error={!!errors.title}
                    helperText={errors.title}
                    id="title"
                    label="Title"
                    margin="normal"
                    required
                    autoFocus
                    fullWidth
                />

                <DefaultEditor
                    value={description}
                    onChange={onEditorChange}
                />

                <TextField
                    variant="outlined"
                    value={imageUrl}
                    onChange={({ target }) => setImageUrl(target.value)}
                    id="imageUrl"
                    label="Image URL"
                    margin="normal"
                    fullWidth
                />

            </EditorDialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant="contained">
                    Cancel
                </Button>

                <Button
                    onClick={handleSaveClick}
                    variant="contained"
                    color="primary"
                >
                    Save &amp; Publish
                </Button>
            </DialogActions>

        </EditorDialog>
    );
}

// Styles
const EditorDialog = styled(Dialog)`
    .paper {
        padding: ${({ theme }) => theme.spacing(5)};
        padding-bottom:  ${({ theme }) => theme.spacing(7)};
    }
`;

const EditorDialogtitle = styled(DialogTitle)`
    padding-bottom:  ${({ theme }) => theme.spacing(5)};
    font-size: 1.3rem;
    font-weight: 500;
`;

const EditorDialogContent = styled(DialogContent)`
    width: 525px;
    padding-right: ${({ theme }) => theme.spacing(3)};
    margin-bottom: ${({ theme }) => theme.spacing(5)};

    & > * {
        margin-bottom: ${({ theme }) => theme.spacing(3)};
    }
`;
