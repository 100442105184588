import * as React from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

import {
    FirestoreAdminUserRole,
} from "@resistance-tech/api";
import { SheetDigitalization } from "./sheetDigitalization/sheetDigitalization";

import { getGlobalServices } from "../../services/services";
import { selectCurrentUser, selectCurrentUserAdminUserConfig } from "../../store/selectors";
import { IAppState } from "../../store/state";

export function SheetsManager() {
    // Redux state
    const globalServices = getGlobalServices();
    const currentUser = useSelector((state: IAppState) => selectCurrentUser(state));
    const userAdmin = useSelector((state: IAppState) => selectCurrentUserAdminUserConfig(state));

    if (!userAdmin) {
        return (<></>);
    }

    const { roles } = userAdmin;
    let { sheetPreferences } = userAdmin;

    if (!sheetPreferences) {
        sheetPreferences = {};
    }

    return (
        <ManagerContainer>
            {globalServices?.dataService && currentUser?.uid
                && (
                    <SheetDigitalization
                        dataService={globalServices.dataService}
                        functionsService={globalServices.functionsService}
                        hasAdminRole={!!roles?.includes(FirestoreAdminUserRole.Admin)}
                        sheetPreferences={sheetPreferences}
                    />
                )}
        </ManagerContainer>
    );
}

// Styles
const ManagerContainer = styled("div")`
    flex-grow: 1;
    padding: 0px;
`;
