import * as React from "react";
import { styled } from "@mui/material/styles";
import {
    Button,
    Grid,
    IconButton,
    TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

interface Props {
    list: string[];
    setList: (list: string[]) => void;
}

export function AppendableList({ list, setList }: Props) {
    const handleItemChange = (newValue: string, index: number) => {
        const newList = [...list];
        newList[index] = newValue;

        setList(newList);
    };

    const handleDeleteClick = (index: number) => {
        const newList = [...list];
        newList.splice(index, 1);

        setList(newList);
    };

    const handleAddClick = () => {
        setList([...list, `Choice #${list.length + 1}`]);
    };

    return (
        <>
            {list.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
                <Grid container key={index} alignItems="center">
                    <Grid item xs>
                        <TextField
                            variant="outlined"
                            value={item}
                            onChange={({ target }) => handleItemChange(target.value, index)}
                            margin="normal"
                            fullWidth
                        />
                    </Grid>

                    <Grid item>
                        <IconButton onClick={() => handleDeleteClick(index)} size="large">
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            ))}

            <AddButton
                onClick={handleAddClick}
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
            >
                Add choice
            </AddButton>
        </>
    );
}

const AddButton = styled(Button)`
    margin-top: ${({ theme }) => theme.spacing(3)};
    margin-bottom: ${({ theme }) => theme.spacing(1)};
`;
