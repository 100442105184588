import React, { useState } from "react";
import { IName, INameFieldType } from "@resistance-tech/api";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";
import { InputChangeHandler } from "./input.types";

const DEFAULT_NAME = {
    nameTitle: "",
    firstName: "",
    lastName: "",
};

const StyledContainer = styled("div")`
    display: flex;
    width: 100%;
`;

const NameTitleAutocomplete = styled(Autocomplete)`
    padding-right: ${({ theme }) => theme.spacing(1)};
    flex: 0 0 3em;
`;

const NamePartTextField = styled(TextField)`
    padding-right: ${({ theme }) => theme.spacing(1)};
    flex: 1 1 10em;
`;

export function NameInput({ initialValue = DEFAULT_NAME, inputChangeHandler = () => {} }
: { initialValue?: IName, inputChangeHandler?: InputChangeHandler}) {
    const [name, setName] = useState(initialValue);
    const {
        firstName, lastName, nameTitle,
    } = name;

    const handleChange = (
        field: INameFieldType,
        newValue: string,
    ) => {
        setName((prevName) => {
            const newName = { ...prevName };
            newName[field] = newValue;
            return newName;
        });
    };

    return (
        <StyledContainer>
            <NameTitleAutocomplete
                options={["Dr.", "dr.", "Prof.", "PhD"]}
                freeSolo
                autoSelect
                autoComplete
                value={nameTitle}
                onInputChange={(_, title) => handleChange("nameTitle", title)}
                renderInput={(params: any) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <TextField {...params} onBlur={() => inputChangeHandler(name)} />
                )}
            />
            <NamePartTextField
                autoComplete="off"
                inputProps={{ style: { textTransform: "capitalize" } }}
                value={lastName}
                onChange={(e) => handleChange("lastName", e.target.value)}
                onBlur={() => inputChangeHandler(name)}
            />
            <NamePartTextField
                autoComplete="off"
                inputProps={{ style: { textTransform: "capitalize" } }}
                value={firstName}
                onChange={(e) => handleChange("firstName", e.target.value)}
                onBlur={() => inputChangeHandler(name)}
            />
        </StyledContainer>
    );
}
