import { Timestamp } from "firebase/firestore";
import * as React from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import moment from "moment";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from "@mui/material";
import { getGlobalServices } from "../../services/services";
import { selectCurrentUser, selectEvent } from "../../store/selectors";
import { IAppState } from "../../store/state";
import { EditorRequest } from "../../types";

interface IProps {
    request: EditorRequest | undefined;
    onClose: () => void;
}

export function EventEditor({
    request, onClose,
}: IProps) {
    const isOpen = request !== undefined;
    const eventId = request?.type === "update" ? request?.itemId : undefined;

    // Redux state
    const user = useSelector((state: IAppState) => selectCurrentUser(state));

    const currentEvent = useSelector((state: IAppState) => selectEvent(state, eventId ?? ""));

    // Local state
    const [title, setTitle] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [date, setDate] = React.useState("");
    const [location, setLocation] = React.useState("");
    const [imageUrl, setImageUrl] = React.useState("");
    const [fbEventUrl, setFbEventUrl] = React.useState("");

    const [errors, setErrors] = React.useState<{ [property: string]: string | undefined }>({});

    React.useEffect(() => {
        if (isOpen && currentEvent) {
            if (currentEvent.title) {
                setTitle(currentEvent.title);
            }

            if (currentEvent.description) {
                setDescription(currentEvent.description);
            }

            if (currentEvent.date) {
                setDate(moment(currentEvent.date.seconds * 1000).format("YYYY-MM-DDTHH:mm:ss"));
            }

            if (currentEvent.imageUrl) {
                setImageUrl(currentEvent.imageUrl);
            }

            if (currentEvent.location) {
                setLocation(currentEvent.location);
            }

            if (currentEvent.fbEventUrl) {
                setFbEventUrl(currentEvent.fbEventUrl);
            }
        }
    }, [isOpen, currentEvent]);

    const handleClose = () => {
        onClose();

        setTitle("");
        setDescription("");
        setDate("");
        setImageUrl("");
        setLocation("");
        setFbEventUrl("");
        setErrors({});
    };

    const handleDialogClose = (_event: {}, reason: "backdropClick" | "escapeKeyDown") => {
        if (reason === "backdropClick") {
            return;
        }
        handleClose();
    };

    const handleSaveClick = async () => {
        const globalServices = getGlobalServices();
        if (globalServices === undefined) {
            return;
        }

        const newErrors = {
            title: !title ? "Please give a title for your event" : undefined,
            description: !description ? "Please give a description for your event" : undefined,
            date: !date ? "Please select a date for your event" : undefined,
            location: !location ? "Please give a location for your event" : undefined,
            imageUrl: !imageUrl ? "Please give an imageUrl for your event" : undefined,
        };
        setErrors(newErrors);

        if (Object.values(newErrors).some((error) => error)) {
            return;
        }

        handleClose();

        const eventProps = {
            title,
            description,
            date,
            imageUrl,
            location,
            fbEventUrl,
        };

        const { dataService } = globalServices;
        if (request?.type === "update") {
            await dataService.updateEvent(request.itemId, {
                ...eventProps,
                date: Timestamp.fromDate(new Date(date)),
            });
        } else if (user !== undefined) {
            await dataService.createEvent({
                ...eventProps,
                date: Timestamp.fromDate(new Date(date)),
            });
        }
    };

    const dialogTitle = currentEvent ? "Edit event" : "Create event";

    return (
        <EditorDialog
            open={isOpen}
            onClose={handleDialogClose}
            aria-labelledby="form-dialog-title"
            classes={{ paper: "paper" }}
        >
            <EditorDialogtitle id="form-dialog-title">
                {dialogTitle}
            </EditorDialogtitle>

            <EditorDialogContent>
                <TextField
                    variant="outlined"
                    value={title}
                    onChange={({ target }) => setTitle(target.value)}
                    error={!!errors.title}
                    helperText={errors.title}
                    id="title"
                    label="Title"
                    margin="normal"
                    required
                    autoFocus
                    fullWidth
                />

                <TextField
                    variant="outlined"
                    value={description}
                    onChange={({ target }) => setDescription(target.value)}
                    error={!!errors.description}
                    helperText={errors.description}
                    id="description"
                    label="Description"
                    margin="normal"
                    multiline
                    required
                    fullWidth
                />

                <TextField
                    variant="outlined"
                    id="datetime-local"
                    label="Event Date"
                    value={date}
                    onChange={({ target }) => {
                        setDate(target.value);
                    }}
                    error={!!errors.date}
                    helperText={errors.date}
                    type="datetime-local"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    required
                    fullWidth
                />

                <TextField
                    variant="outlined"
                    value={location}
                    onChange={({ target }) => setLocation(target.value)}
                    error={!!errors.location}
                    helperText={errors.location}
                    id="location"
                    label="Location"
                    margin="normal"
                    required
                    multiline
                    fullWidth
                />

                <TextField
                    variant="outlined"
                    value={imageUrl}
                    onChange={({ target }) => setImageUrl(target.value)}
                    error={!!errors.imageUrl}
                    helperText={errors.imageUrl}
                    id="imageUrl"
                    label="Image URL"
                    margin="normal"
                    required
                    fullWidth
                />

                <TextField
                    variant="outlined"
                    value={fbEventUrl}
                    onChange={({ target }) => setFbEventUrl(target.value)}
                    id="fbEventUrl"
                    label="Facebook Event URL"
                    margin="normal"
                    fullWidth
                />

            </EditorDialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant="contained">
                    Cancel
                </Button>

                <Button
                    onClick={handleSaveClick}
                    variant="contained"
                    color="primary"
                >
                    Save &amp; Publish
                </Button>
            </DialogActions>

        </EditorDialog>
    );
}

// Styles
const EditorDialog = styled(Dialog)`
    .paper {
        padding: ${({ theme }) => theme.spacing(5)};
        padding-bottom:  ${({ theme }) => theme.spacing(7)};
    }
`;

const EditorDialogtitle = styled(DialogTitle)`
    padding-bottom:  ${({ theme }) => theme.spacing(5)};
    font-size: 1.3rem;
    font-weight: 500;
`;

const EditorDialogContent = styled(DialogContent)`
    padding-right: ${({ theme }) => theme.spacing(3)};
    margin-bottom: ${({ theme }) => theme.spacing(5)};

    & > * {
        margin-bottom: ${({ theme }) => theme.spacing(3)};
    }
`;
