import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { Button, TextField } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import AddCommentIcon from "@mui/icons-material/AddComment";
import { InputChangeHandler } from "./input.types";

const useStyles = makeStyles((theme: Theme) => createStyles({
    button: {
        display: "flex",
        height: "100%",
        alignItems: "center",
        border: 0,
        backgroundColor: "inherit",

    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: "30em",
        height: "20em",

    },
    comment: {
        width: "100%",
        height: "80%",
    },
    closeButton: {
        float: "right",
    },
}));

export function CommentInput({ initialValue = "", inputChangeHandler = () => {} }
    : {initialValue?: string, inputChangeHandler?: InputChangeHandler<string>}) {
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(false);
    const [comment, setComment] = useState<string>(initialValue);

    useEffect(() => {
        inputChangeHandler(comment);
    }, [comment, inputChangeHandler]);

    return (
        <div>
            <button
                className={classes.button}
                color="primary"
                type="button"
                onClick={() => setOpen(true)}
            >
                <AddCommentIcon />
            </button>
            <Modal
                className={classes.modal}
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <TextField className={classes.comment} multiline maxRows={4} label="Megjegyzés" value={comment} onChange={(e) => setComment(e.target.value)} />
                        <Button className={classes.closeButton} variant="contained" color="primary" onClick={() => setOpen(false)}>Bezár</Button>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
