import {
    Dialog, DialogContent,
} from "@mui/material";
import React, { useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import { deserializeActivismEvent } from "@resistance-tech/api";
import { ActivismState } from "./activism.types";
import { ComponentStateContext } from "./activism.state";
import { EventDetails } from "./eventDetails";
import { selectCurrentUser, selectCurrentUserAdminUserConfig } from "../../store/selectors";
import { getGlobalServices } from "../../services/services";

export function EventDetailsDialog() {
    const { dispatch, state } = useContext(ComponentStateContext);
    const { events, openEventId } = state;
    const event = openEventId === undefined
        ? undefined
        : events.find((eventCandidate) => eventCandidate.id === openEventId);
    const user = useSelector(selectCurrentUser);
    const currentUserId = user?.uid ?? "";
    const adminUserConfig = useSelector(selectCurrentUserAdminUserConfig);
    const currentUserRoles = adminUserConfig?.roles ?? [];

    const handleClose = useCallback(() => {
        dispatch({ type: ActivismState.ACTIVISM_CLOSE_EVENT_VIEWER });
    }, [dispatch]);

    const handleEdit = useCallback(() => {
        if (openEventId === undefined) {
            return;
        }
        handleClose();
        dispatch({ type: ActivismState.ACTIVISM_OPEN_EVENT_EDITOR, payload: { eventId: openEventId } });
    }, [openEventId, dispatch, handleClose]);

    const handleSignUpOrSignOff = useCallback((isAdding: boolean) => {
        const execute = async () => {
            const globalServices = getGlobalServices();
            if (globalServices === undefined || openEventId === undefined) {
                return;
            }
            const request = {
                eventId: openEventId,
                participantUserId: currentUserId,
            };
            const response = isAdding
                ? await globalServices.functionsService.activismAddParticipant(request)
                : await globalServices.functionsService.activismRemoveParticipant(request);
            const updatedEvent = deserializeActivismEvent(response.event);
            dispatch({ type: ActivismState.ACTIVISM_ADD_EVENTS, payload: { events: [updatedEvent] } });
        };
        return execute();
    }, [dispatch, currentUserId, openEventId]);

    const handleSignUp = useCallback(() => handleSignUpOrSignOff(true), [handleSignUpOrSignOff]);

    const handleSignOff = useCallback(() => handleSignUpOrSignOff(false), [handleSignUpOrSignOff]);

    return (
        <Dialog onClose={handleClose} open={openEventId !== undefined}>
            <DialogContent>
                {event !== undefined && (
                    <EventDetails
                        event={event}
                        currentUserId={currentUserId}
                        currentUserRoles={currentUserRoles}
                        onClose={handleClose}
                        onSignUp={handleSignUp}
                        onSignOff={handleSignOff}
                        onEdit={handleEdit}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
}
