import { IFormSchema, ISignatureData } from "@resistance-tech/api";
import React, { Dispatch } from "react";
import { assertNever } from "../../../utils/general";
import {
    ActionType, IComponentState, SheetState,
} from "./sheetDigitalization.types";

const createDefaultSignature = (schema: IFormSchema) => {
    const defaultSignature: ISignatureData = {
        name: {
            nameTitle: "",
            firstName: "",
            lastName: "",
        },
        address: {
            postCode: "",
            city: "",
            street: "",
            number: "",
        },
    };
    if (schema.fields.includes("phoneNumber")) {
        defaultSignature.phoneNumber = "";
    }
    if (schema.fields.includes("emailAddress")) {
        defaultSignature.emailAddress = "";
    }

    if (schema.fields.includes("consentToGoal")) {
        defaultSignature.consentToGoal = false;
    }
    if (schema.fields.includes("consentToEmail")) {
        defaultSignature.consentToEmail = false;
    }
    if (schema.fields.includes("consentToPhone")) {
        defaultSignature.consentToPhone = false;
    }
    if (schema.fields.includes("consentToContact")) {
        defaultSignature.consentToContact = false;
    }
    if (schema.fields.includes("comment")) {
        defaultSignature.comment = "";
    }
    return defaultSignature;
};

export const DEFAULT_STATE : IComponentState = {
    schema: undefined,
    submission: {
        sheet: "",
        sheetId: "",
        data: [],
    },
    imageUrl: undefined,
    errorMessage: undefined,
    requestInProgress: true,
    action: undefined,
};

export const ComponentStateContext = React.createContext<{
    state: IComponentState,
    dispatch: Dispatch<ActionType>
}>({ state: DEFAULT_STATE, dispatch: () => {} });

export const reducer = (originalState : IComponentState, action: ActionType) : IComponentState => {
    const { schema, submission } = originalState;
    switch (action.type) {
    case SheetState.SHEET_RESET:
        return { ...DEFAULT_STATE };
    case SheetState.SHEET_REQUESTED:
        return {
            ...originalState, requestInProgress: true, errorMessage: undefined, action: undefined,
        };
    case SheetState.SHEET_REPORTED:
        return {
            ...originalState,
            action: "report",
            requestInProgress: true,
            errorMessage: undefined,
        };
    case SheetState.SHEET_ARCHIVED:
        return {
            ...originalState,
            action: "archive",
            requestInProgress: true,
            errorMessage: undefined,
        };
    case SheetState.SHEET_ASSIGNED:
        const { assignedSheetId, previousSubmission } = action.payload;
        if (!action.payload.schema) {
            return originalState;
        }

        const numberOfRows = action.payload.schema?.defaultNumberOfRows ?? 5;
        const emptyDataArray: ISignatureData[] = Array(numberOfRows)
            .fill(createDefaultSignature(action.payload.schema), 0, numberOfRows);
        const currentlyTypedOrDefaultData = submission.data?.length > 0 ? submission.data : emptyDataArray;
        const data = previousSubmission === undefined ? currentlyTypedOrDefaultData : previousSubmission.data;
        const sheetId = previousSubmission?.sheetId ?? submission.sheetId;
        return {
            ...originalState,
            schema: action.payload.schema,
            submission:
                 {
                     ...submission,
                     sheet: assignedSheetId,
                     data,
                     sheetId,
                 },
            imageUrl: action.payload.imageUrl,
            errorMessage: undefined,
            requestInProgress: false,
        };
    case SheetState.SHEET_INVALID:
        return {
            ...originalState,
            errorMessage: action.payload.message,
            requestInProgress: false,
        };
    case SheetState.SHEET_COMPLETED:
        return {
            ...originalState,
            schema: undefined,
            submission: {
                sheet: "", sheetId: "", data: [],
            },
            errorMessage: undefined,
            requestInProgress: false,
            action: "complete",
        };
    case SheetState.SHEET_ID_CHANGED:
        return {
            ...originalState, schema, submission: { ...submission, sheetId: action.payload }, errorMessage: undefined,
        };
    case SheetState.SIGNATURE_CHANGED:
        const signatureData = { ...submission.data[action.payload.index] };
        submission.data[action.payload.index] = signatureData;
        (signatureData[action.payload.field] as unknown) = action.payload.value;
        return {
            ...originalState,
        };
    case SheetState.SIGNATURE_ADDED:
        if (!schema) {
            return originalState;
        }
        const signature = createDefaultSignature(schema);
        return { ...originalState, submission: { ...submission, data: [...submission.data, signature] } };
    default:
        assertNever(action, "Unexpected action type");
        return originalState;
    }
};
