import React, { useEffect, useReducer } from "react";
import { CollectionId, ISheetCounterHttpRequest, ISheetCounters } from "@resistance-tech/api";

import { styled } from "@mui/material/styles";
import {
    Button, Card, Divider, Icon,
} from "@mui/material";
import { PieChart as PieChartIcon } from "@mui/icons-material";
import { getGlobalServices } from "../../../services/services";
import { DEFAULT_STATE, reducer } from "./sheetStatistics.state";
import { SheetStatisticsAction } from "./sheetStatistics.type";
import { PieChart } from "./pieChart";

export function SheetStatistics() {
    const [currentState, dispatch] = useReducer(reducer, DEFAULT_STATE);

    const { sheetCounters } = currentState;

    const globalServices = getGlobalServices();

    useEffect(() => {
        const getSchemaList = async () => {
            if (!globalServices) {
                return;
            }
            dispatch({
                type: SheetStatisticsAction.SHEET_SCHEMA_LIST_REQUESTED,
            });

            const schemaDocuments = await globalServices?.dataService.getAllDocuments(CollectionId.SheetsSchemas);
            dispatch({
                type: SheetStatisticsAction.SHEET_SCHEMA_LIST_RESPONSE,
                payload: Object.keys(schemaDocuments),
            });
        };

        getSchemaList();
    }, [globalServices]);

    const getStatistics = async (schemaId: string) => {
        if (!globalServices) {
            return;
        }
        dispatch({
            type: SheetStatisticsAction.SHEET_STATISTICS_REQUESTED,
            payload: schemaId,
        });

        if (schemaId === undefined) {
            return;
        }

        const request: ISheetCounterHttpRequest = {
            schemaId,
        };
        const value: ISheetCounters = await globalServices.functionsService.handleGetSheetStatistics(request);

        dispatch({
            type: SheetStatisticsAction.SHEET_STATISTICS_RESPONSE,
            payload: {
                schemaId,
                counters: value,
            },
        });
    };

    const reconcileStatistics = async (schemaId: string) => {
        if (!globalServices) {
            return;
        }

        if (schemaId === undefined) {
            return;
        }

        if (!window.confirm("Ez eltarthat egy darabig. Biztosan szeretnéd?")) {
            return;
        }
        dispatch({
            type: SheetStatisticsAction.SHEET_RECONCILE_STATISTICS_REQUESTED,
        });

        const request: ISheetCounterHttpRequest = {
            schemaId,
        };
        const value: ISheetCounters = await globalServices.functionsService.handleReconcileSheetStatistics(request);

        dispatch({
            type: SheetStatisticsAction.SHEET_RECONCILE_STATISTICS_RESPONSE,
            payload: {
                schemaId,
                counters: value,
            },
        });
    };

    const statistics = (schemaId: string) => {
        if (sheetCounters === undefined) {
            return null;
        }
        const counters = sheetCounters[schemaId];
        if (counters === undefined) {
            return null;
        }
        const currentlyAssigned = counters.assigned - counters.completed - counters.revoked - counters.reported;
        const remains = counters.sheetCount - counters.completed - currentlyAssigned;
        return (
            <div>
                <PieChart
                    numberOfSheets={counters.sheetCount}
                    currentlyAssigned={currentlyAssigned}
                    completed={counters.completed}
                    remains={remains}
                    width="400"
                    height="400"
                />
                <div>Sheet statistics</div>
                <div>
                    Number of sheets:
                    {counters.sheetCount}
                </div>
                <div>
                    Currently assigned:
                    {currentlyAssigned}
                </div>
                <div>
                    Revoked:
                    {counters.revoked}
                </div>
                <div>
                    Completed:
                    {counters.completed}
                </div>
                <div>
                    Reported:
                    {counters.reported}
                </div>
                <div>
                    Archived:
                    {counters.archived}
                </div>
                <div>
                    Remains:
                    {remains}
                </div>
            </div>
        );
    };

    const submitterPerformance = (schemaId: string) => {
        if (sheetCounters === undefined) {
            return null;
        }
        const counters = sheetCounters[schemaId];
        if (counters === undefined) {
            return null;
        }

        const maxCount = Math.max(
            ...Object.values(counters.userCompletion).map((userCompletion) => userCompletion.count),
        );
        const maxEntryCount = Math.max(
            ...Object.values(counters.userCompletion).map((userCompletion) => userCompletion.entryCount ?? 0),
        );
        const userIdsOrdered = Object.keys(counters.userCompletion).sort((a, b) => {
            const valueA = counters.userCompletion[a].name;
            const valueB = counters.userCompletion[b].name;
            return valueA.localeCompare(valueB);
        });
        return (
            <table>
                <tbody>
                    {userIdsOrdered.map((user) => (
                        submitter(
                            counters.userCompletion[user].email,
                            counters.userCompletion[user].name,
                            counters.userCompletion[user].count,
                            maxCount,
                            counters.userCompletion[user].entryCount ?? 0,
                            maxEntryCount,
                        )
                    ))}
                </tbody>
            </table>
        );
    };

    // The empty string denotes the TOTAL statistics
    const schemaIdsToMakeBlocksFor = ["", ...currentState.schemaIdList];
    return (
        <>
            <ManagerContainer>
                <div>
                    {schemaIdsToMakeBlocksFor.map((schema) => (
                        <div>
                            <StatisticsButton
                                onClick={() => getStatistics(schema)}
                                disabled={currentState.requestInProgress}
                                variant="contained"
                                color="primary"
                                aria-label={schema === "" ? "Összes" : schema}
                            >
                                <Icon><PieChartIcon /></Icon>
                                &nbsp;
                                {schema === "" ? "Összes" : schema}
                            </StatisticsButton>
                            &nbsp;
                            <StatisticsCard>
                                {statistics(schema)}
                            </StatisticsCard>
                            <StatisticsCard>
                                {submitterPerformance(schema)}
                            </StatisticsCard>
                            {schema !== "" && (
                                <ReconcileButton
                                    onClick={() => reconcileStatistics(schema)}
                                    disabled={currentState.requestInProgress}
                                    variant="outlined"
                                    size="small"
                                    aria-label="Reconcile"
                                >
                                    Reconcile
                                </ReconcileButton>
                            )}
                            <Divider />
                        </div>
                    ))}
                </div>

            </ManagerContainer>
        </>
    );
}

function submitter(
    email: string,
    name: string,
    count: number,
    maxCount: number,
    entryCount: number,
    maxEntryCount: number,
) {
    return (
        <>
            <tr key={email}>
                <td>
                    {name}
                </td>
                <td>
                    {email}
                </td>
                <PerformaceBarTd>
                    <PerformanceBar percentage={maxEntryCount === 0 ? 0 : (entryCount / maxEntryCount) * 100}>
                        &nbsp;
                    </PerformanceBar>
                </PerformaceBarTd>
                <td>
                    {entryCount}
                    ✍️
                </td>
                <PerformaceBarTd>
                    <PerformanceBar percentage={maxCount === 0 ? 0 : (count / maxCount) * 100}>
                        &nbsp;
                    </PerformanceBar>
                </PerformaceBarTd>
                <td>
                    {count}
                    📄
                </td>
            </tr>
        </>
    );
}

const PerformanceBar = styled("div")<{ percentage: number }>`
    background-color: red;
    width: ${(props) => props.percentage}%;
    border-radius: 10px;
    height: 10px;
`;

const PerformaceBarTd = styled("td")`
    width: 100px;
    border: lightgray;
    border-style: solid;
    border-width: 1px;
    border-radius: 10px;
`;

const StatisticsButton = styled(Button)`
    margin: ${({ theme }) => theme.spacing(5, 0)};
    width: 100%;
    min-width: 400px;
`;

const ReconcileButton = styled(Button)`
    margin: ${({ theme }) => theme.spacing(5, 0)};
`;

const ManagerContainer = styled("div")`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    overflow-y: inherit;
`;

const StatisticsCard = styled(Card)`
    margin-bottom: ${({ theme }) => theme.spacing(3)};
  justify-content: flex-end;

  & > * {
    padding-left: ${({ theme }) => theme.spacing(5)};
    padding-right: ${({ theme }) => theme.spacing(5)};

    :not(:first-of-type) {
      margin-left: ${({ theme }) => theme.spacing(3)};
    }
  }
`;
