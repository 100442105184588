import * as React from "react";
import { styled } from "@mui/material/styles";
import DocumentTitle from "react-document-title";
import { useLocation } from "react-router";
import { Navigate, Route, Routes } from "react-router-dom";
import { FirestoreAdminUserRole } from "@resistance-tech/api";
import { NavUtils, Page } from "../utils/navUtils";
import { ScrollToTop } from "./common/scrollToTop";
import { Login } from "./general/login";
import { PageFrame } from "./general/pageFrame";
import { StaticContent } from "./general/staticContent";
import { SurveyManager } from "./mobile/surveyManager";
import { PostManager } from "./mobile/postManager";
import { EventManager } from "./mobile/eventManager";
import { JobsOverview } from "./jobs/jobsOverview";
import { SheetAdminManager } from "./sheets/sheetAdmin/sheetAdminManager";
import { TipsManager } from "./mobile/tipManager";
import { SheetsManager } from "./sheets/sheetsManager";
import { SheetUploadManager } from "./sheets/sheetUpload/sheetUploadManager";
import { SheetStatistics } from "./sheets/sheetStatistics/sheetStatistics";
import { SchemaList } from "./sheets/schema/schemaList";
import { Activism } from "./activism/activism";
import { getGlobalServices } from "../services/services";

const APP_TYPE = "resistance" as const;

export function ResistanceApp() {
    const location = useLocation();

    /** Log screen views when the path changes */
    React.useEffect(() => {
        getGlobalServices()?.firebaseAnalyticsService.logPageView();
    }, [location.pathname]);

    const renderRouteAuth = () => {
        const { redirectUrl } = NavUtils.getNavUrlQueryParams[Page.SignIn](location.search);

        return (
            <PageFrame title={NavUtils.getNavUrlSimpleTitle[Page.SignIn]} appType={APP_TYPE}>
                <Login redirectUrl={redirectUrl} />
            </PageFrame>
        );
    };

    return (
        <DocumentTitle title={NavUtils.getNavUrlSimpleTitle[Page.Home]}>
            <ScrollToTop>
                <AppContainer>
                    <AppContent>
                        <Routes>
                            <Route
                                path={NavUtils.getNavUrlTemplate[Page.Home]}
                                element={<Navigate to={NavUtils.getNavUrl[Page.MobilePosts]()} />}
                            />
                            <Route
                                path={NavUtils.getNavUrlTemplate[Page.SignIn]}
                                element={renderRouteAuth()}
                            />
                            <Route
                                path={NavUtils.getNavUrlTemplate[Page.TermsOfService]}
                                element={(
                                    <PageFrame
                                        title={NavUtils.getNavUrlSimpleTitle[Page.TermsOfService]}
                                        appType={APP_TYPE}
                                    >
                                        <StaticContent type="terms of service" />
                                    </PageFrame>
                                )}
                            />
                            <Route
                                path={NavUtils.getNavUrlTemplate[Page.PrivacyPolicy]}
                                element={(
                                    <PageFrame
                                        title={NavUtils.getNavUrlSimpleTitle[Page.PrivacyPolicy]}
                                        appType={APP_TYPE}
                                    >
                                        <StaticContent type="privacy policy" />
                                    </PageFrame>
                                )}
                            />

                            <Route
                                path={NavUtils.getNavUrlTemplate[Page.MobilePosts]}
                                element={(
                                    <PageFrame
                                        title={NavUtils.getNavUrlSimpleTitle[Page.MobilePosts]}
                                        authentication={[FirestoreAdminUserRole.MobileEditor]}
                                        appType={APP_TYPE}
                                    >
                                        <PostManager />
                                    </PageFrame>
                                )}
                            />
                            <Route
                                path={NavUtils.getNavUrlTemplate[Page.MobileSurveys]}
                                element={(
                                    <PageFrame
                                        title={NavUtils.getNavUrlSimpleTitle[Page.MobileSurveys]}
                                        authentication={[FirestoreAdminUserRole.MobileEditor]}
                                        appType={APP_TYPE}
                                    >
                                        <SurveyManager />
                                    </PageFrame>
                                )}
                            />
                            <Route
                                path={NavUtils.getNavUrlTemplate[Page.MobileEvents]}
                                element={(
                                    <PageFrame
                                        title={NavUtils.getNavUrlSimpleTitle[Page.MobileEvents]}
                                        authentication={[FirestoreAdminUserRole.MobileEditor]}
                                        appType={APP_TYPE}
                                    >
                                        <EventManager />
                                    </PageFrame>
                                )}
                            />
                            <Route
                                path={NavUtils.getNavUrlTemplate[Page.MobileTips]}
                                element={(
                                    <PageFrame
                                        title={NavUtils.getNavUrlSimpleTitle[Page.MobileTips]}
                                        authentication={[FirestoreAdminUserRole.MobileEditor]}
                                        appType={APP_TYPE}
                                    >
                                        <TipsManager />
                                    </PageFrame>
                                )}
                            />

                            <Route
                                path={NavUtils.getNavUrlTemplate[Page.JobsOverview]}
                                element={(
                                    <PageFrame
                                        title={NavUtils.getNavUrlSimpleTitle[Page.JobsOverview]}
                                        authentication={[FirestoreAdminUserRole.JobsEditor]}
                                        appType={APP_TYPE}
                                    >
                                        <JobsOverview />
                                    </PageFrame>
                                )}
                            />

                            <Route
                                path={NavUtils.getNavUrlTemplate[Page.SheetTyping]}
                                element={(
                                    <PageFrame
                                        title={NavUtils.getNavUrlSimpleTitle[Page.SheetTyping]}
                                        authentication={[FirestoreAdminUserRole.SheetSubmitter]}
                                        appType={APP_TYPE}
                                    >
                                        <SheetsManager />
                                    </PageFrame>
                                )}
                            />
                            <Route
                                path={NavUtils.getNavUrlTemplate[Page.SheetUpload]}
                                element={(
                                    <PageFrame
                                        title={NavUtils.getNavUrlSimpleTitle[Page.SheetUpload]}
                                        authentication={[FirestoreAdminUserRole.SheetUploader]}
                                        appType={APP_TYPE}
                                    >
                                        <SheetUploadManager />
                                    </PageFrame>
                                )}
                            />
                            <Route
                                path={NavUtils.getNavUrlTemplate[Page.SheetStatistics]}
                                element={(
                                    <PageFrame
                                        title={NavUtils.getNavUrlSimpleTitle[Page.SheetStatistics]}
                                        authentication={[FirestoreAdminUserRole.Admin]}
                                        appType={APP_TYPE}
                                    >
                                        <SheetStatistics />
                                    </PageFrame>
                                )}
                            />
                            <Route
                                path={NavUtils.getNavUrlTemplate[Page.SheetSchemas]}
                                element={(
                                    <PageFrame
                                        title={NavUtils.getNavUrlSimpleTitle[Page.SheetSchemas]}
                                        authentication={[FirestoreAdminUserRole.Admin]}
                                        appType={APP_TYPE}
                                    >
                                        <SchemaList />
                                    </PageFrame>
                                )}
                            />

                            <Route
                                path={NavUtils.getNavUrlTemplate[Page.ActivismBooking]}
                                element={(
                                    <PageFrame
                                        title={NavUtils.getNavUrlSimpleTitle[Page.ActivismBooking]}
                                        authentication={[]}
                                        appType={APP_TYPE}
                                    >
                                        <Activism />
                                    </PageFrame>
                                )}
                            />

                            <Route
                                path={NavUtils.getNavUrlTemplate[Page.Admin]}
                                element={(
                                    <PageFrame
                                        title={NavUtils.getNavUrlSimpleTitle[Page.Admin]}
                                        authentication={[FirestoreAdminUserRole.Admin]}
                                        appType={APP_TYPE}
                                    >
                                        <SheetAdminManager />
                                    </PageFrame>
                                )}
                            />

                            <Route element={<Navigate to={NavUtils.getNavUrl[Page.Home]()} />} />
                        </Routes>
                    </AppContent>
                </AppContainer>
            </ScrollToTop>
        </DocumentTitle>
    );
}

// Styles
const AppContainer = styled("div")`
    display: flex;
    flex-direction: column;
`;

const AppContent = styled("div")`
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
`;
