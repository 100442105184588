import React, {
    Dispatch, useReducer, useState,
} from "react";

import {
    Button,
} from "@mui/material";
import storage from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { FileProgress, UploadProgressBar } from "./uploadProgressBar";
import { getGlobalServices } from "../../../services/services";
import { assertNever } from "../../../utils/general";
import { FirebaseStorageService } from "../../../services/firebaseStorageService";

import "./sheetUploadManager.scss";
import { SchemaSelectControl } from "../schema/schemaSelectControl";

enum ProgressType {
    FILE_ADDED,
    SET_PROGRESS,
    SET_UPLOAD_STATE,
    UPLOAD_FAILED,
}

interface FileAddedAction {
    type: ProgressType.FILE_ADDED;
    id: string;
    payload: {
        fileName: string;
    }
}

interface SetProgressAction {
    type: ProgressType.SET_PROGRESS;
    id: string;
    payload: {
        totalBytes: number;
        bytesTransferred: number;
    }
}

interface SetUploadStateAction {
    type: ProgressType.SET_UPLOAD_STATE;
    id: string;
    payload: {
        state: string;
    }
}

interface UploadFailedAction {
    type: ProgressType.UPLOAD_FAILED;
    id: string;
}

type ProgressAction = FileAddedAction | SetProgressAction | SetUploadStateAction | UploadFailedAction;

const uploadHandler = (
    files: FileList,
    selectedSchema: string | undefined,
    dispatch: Dispatch<ProgressAction>,
    firebaseStorageService: FirebaseStorageService,
) => {
    if (files === null || files.item.length === 0 || !selectedSchema) {
        return;
    }

    const filesDispatchData: FileAddedAction[] = Array.from(files).map((file) => ({
        type: ProgressType.FILE_ADDED,
        id: uuidv4(),
        payload: { fileName: file.name },
    }));

    const tasks = firebaseStorageService.storeSheetList(selectedSchema, files);
    if (!tasks) {
        return;
    }

    filesDispatchData
        .filter((data) => data !== undefined)
        .forEach((dispatchData) => { dispatch(dispatchData); });

    tasks.forEach((task, index) => {
        const { id } = filesDispatchData[index];
        if (!task) {
            dispatch({ type: ProgressType.UPLOAD_FAILED, id });
            return;
        }
        const progressing = (snapshot: storage.UploadTaskSnapshot) => {
            const { state, bytesTransferred, totalBytes } = snapshot;
            switch (state) {
            case "running":
                dispatch({
                    type: ProgressType.SET_PROGRESS,
                    id,
                    payload: {
                        bytesTransferred,
                        totalBytes,
                    },
                });
                break;
            case "success":
                dispatch({ type: ProgressType.SET_UPLOAD_STATE, id, payload: { state } });
                break;
            default:
            }
        };

        const uploadFailed = () => dispatch({
            type: ProgressType.SET_UPLOAD_STATE,
            id,
            payload: { state: "error" },
        });

        const uploadCompleted = () => dispatch({
            type: ProgressType.SET_UPLOAD_STATE,
            id,
            payload: { state: "success" },
        });

        task.on("state_changed", progressing, uploadFailed, uploadCompleted);
    });
};

const handleSetProgress = (prevState: FileProgress[], action: SetProgressAction) => {
    const { id, payload } = action;
    const index = prevState.findIndex((fileProgress) => fileProgress.id === id);
    if (index < 0) {
        return prevState;
    }

    const state = [...prevState];
    const fileProgress = state[index];
    fileProgress.totalBytes = payload.totalBytes;
    fileProgress.bytesTransferred = payload.bytesTransferred;
    fileProgress.state = "running";
    return state;
};

const handleSetUploadState = (prevState: FileProgress[], action: SetUploadStateAction) => {
    const { id, payload } = action;
    const index = prevState.findIndex((fileProgress) => fileProgress.id === id);
    if (index < 0) {
        return prevState;
    }
    const state = [...prevState];
    const fileProgress = state[index];
    fileProgress.state = payload.state;
    return state;
};

const handleFileAdded = (prevState: FileProgress[], action: FileAddedAction) => {
    const { id, payload } = action;
    return prevState.concat({ id, fileName: payload.fileName, state: "STARTING" });
};

const handleUploadFailed = (prevState: FileProgress[], action: UploadFailedAction) => {
    const { id } = action;
    const index = prevState.findIndex((fileProgress) => fileProgress.id === id);
    if (index < 0) {
        return prevState;
    }
    const state = [...prevState];
    const fileProgress = state[index];
    fileProgress.state = "FAILED";
    return state;
};

const reducer = (prevState: FileProgress[], action: ProgressAction): FileProgress[] => {
    switch (action.type) {
    case ProgressType.FILE_ADDED:
        return handleFileAdded(prevState, action);
    case ProgressType.SET_PROGRESS:
        return handleSetProgress(prevState, action);
    case ProgressType.SET_UPLOAD_STATE:
        return handleSetUploadState(prevState, action);
    case ProgressType.UPLOAD_FAILED:
        return handleUploadFailed(prevState, action);
    default:
        assertNever(action, "Unexpected action");
    }
    return prevState;
};

export function SheetUploadManager() {
    const globalServices = getGlobalServices();
    const [selectedSchema, setSelectedSchema] = useState<string | undefined>(undefined);
    const [uploadProgressList, dispatch] = useReducer(reducer, []);

    const handleFileSelected = React.useCallback((files) => {
        if (!globalServices) {
            return null;
        }
        uploadHandler(
            files, selectedSchema, dispatch, globalServices.firebaseStorageService,
        );
        return null;
    }, [globalServices, selectedSchema]);

    return (
        <div className="container">
            <div className="row">
                <SchemaSelectControl onChange={setSelectedSchema} selectFirst label={undefined} className="schema-id" showInactive={false} />
                <UploadInput onFilesSelected={handleFileSelected} />
            </div>
            <div className="row">
                <div className="progress">
                    <UploadProgressBar fileProgressList={uploadProgressList} />
                </div>
            </div>
        </div>
    );
}

function UploadInput({ onFilesSelected }: { onFilesSelected: (files: FileList) => void }) {
    const onUploadChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const { files } = event.target;
        if (files == null) {
            return;
        }
        onFilesSelected(files);
    };

    return (
        <>
            <label htmlFor="btn-upload">
                <input
                    type="file"
                    id="btn-upload"
                    onChange={onUploadChanged}
                    color="primary"
                    multiple
                    style={{ display: "none" }}
                />
                <Button
                    className="btn-choose"
                    variant="outlined"
                    component="span"
                >
                    Fájl kiválasztása
                </Button>
            </label>
        </>

    );
}
