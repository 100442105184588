/* eslint-disable react/no-danger */
import * as React from "react";
import { styled } from "@mui/material/styles";
import dompurify from "dompurify";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Typography,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { IFirestoreTip } from "@resistance-tech/api";

interface IProps {
    tip: IFirestoreTip;
    onEdit: () => void;
    onDelete: () => void;
}

export function TipView({ tip, onEdit, onDelete }: IProps) {
    const { title, description, imageUrl } = tip;

    const sanitizer = dompurify.sanitize;

    return (
        <TipCard>
            <CardContent>
                <Title variant="h6" gutterBottom>{title}</Title>

                <Description component="p">
                    <b>Description:</b>
                    <br />
                    <div dangerouslySetInnerHTML={{ __html: sanitizer(description) }} />
                </Description>

                <Description component="p">
                    <b>Image: </b>
                    <br />
                    {imageUrl ? <Image src={imageUrl} alt="Tip" /> : "-"}
                </Description>

            </CardContent>

            <TipCardActions>
                <Button onClick={onEdit} startIcon={<Edit />}>
                    Edit
                </Button>

                <Button onClick={onDelete} startIcon={<Delete />}>
                    Delete
                </Button>
            </TipCardActions>
        </TipCard>
    );
}

// Styles
const TipCard = styled(Card)`
  margin: 20px 0 0 0;
  padding: ${({ theme }) => theme.spacing(2, 4)};
`;

const TipCardActions = styled(CardActions)`
  justify-content: flex-end;

  & > * {
    padding-left: ${({ theme }) => theme.spacing(5)};
    padding-right: ${({ theme }) => theme.spacing(5)};

    :not(:first-of-type) {
      margin-left: ${({ theme }) => theme.spacing(3)};
    }
  }
`;

const Title = styled(Typography)`
  font-weight: 500;

  & > a {
    color: black;
  }
`;

const Description = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(5)};
  word-break: break-all;
` as typeof Typography;

const Image = styled("img")`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
`;
