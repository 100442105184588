import { serverTimestamp } from "firebase/firestore";
import * as React from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import {
    IFirestoreSurveyOptionsChoice,
    IFirestoreSurveyOptionsScale,
    IFirestoreSurveyOptionsText,
    SurveyOptions,
    SurveyType,
} from "@resistance-tech/api";
import { getGlobalServices } from "../../services/services";
import { selectCurrentUser, selectSenders, selectSurvey } from "../../store/selectors";
import { IAppState } from "../../store/state";
import { EditorRequest } from "../../types";
import { AppendableList } from "../common/appendableList";
import { assertNever } from "../../utils/general";

const MAX_TITLE_LENGTH = 100;

interface IProps {
    request: EditorRequest | undefined;
    onClose: () => void;
}

export function SurveyEditor({ request, onClose }: IProps) {
    const isOpen = request !== undefined;
    const surveyId = request?.type === "update" ? request?.itemId : undefined;

    // Redux state
    const user = useSelector((state: IAppState) => selectCurrentUser(state));
    const currentSurvey = useSelector((state: IAppState) => (surveyId === undefined
        ? undefined : selectSurvey(state, surveyId)));
    const senders = useSelector((state: IAppState) => Object.entries(selectSenders(state)));

    // Local state
    const [title, setTitle] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [type, setType] = React.useState("");

    const [errors, setErrors] = React.useState<{ [property: string]: string | undefined }>({});

    const [choiceList, setChoiceList] = React.useState(["Choice #1"]);
    const [isMultipleChoice, setMultipleChoice] = React.useState(false);
    const [scaleStart, setScaleStart] = React.useState("1");
    const [scaleEnd, setScaleEnd] = React.useState("10");
    const [textMaxLength, setTextMaxLength] = React.useState("");
    const [senderId, setSenderId] = React.useState("");

    React.useEffect(() => {
        if (isOpen && currentSurvey) {
            if (currentSurvey.title) {
                setTitle(currentSurvey.title);
            }

            if (currentSurvey.description) {
                setDescription(currentSurvey.description);
            }

            if (currentSurvey.type) {
                setType(currentSurvey.type);

                if (currentSurvey.options) {
                    let opts: SurveyOptions;
                    switch (currentSurvey.type) {
                    case SurveyType.Choice:
                        opts = currentSurvey.options as IFirestoreSurveyOptionsChoice;
                        if (opts.values) {
                            setChoiceList(opts.values.map((value: {key: number, label: string}) => value.label));
                        }
                        break;
                    case SurveyType.Scale:
                        setScaleStart("1");
                        setScaleEnd("10");
                        opts = currentSurvey.options as IFirestoreSurveyOptionsScale;
                        if (opts.from) {
                            setScaleStart(opts.from.toString());
                        }
                        if (opts.to) {
                            setScaleEnd(opts.to.toString());
                        }
                        break;
                    case SurveyType.Text:
                        opts = currentSurvey.options as IFirestoreSurveyOptionsText;
                        if (opts.maxLength) {
                            setTextMaxLength(opts.maxLength.toString());
                        }
                        break;
                    default: assertNever(currentSurvey.type, `Unexpected survey type: ${currentSurvey.type}`);
                    }
                }
            }
        }
    }, [isOpen, currentSurvey]);

    const handleClose = () => {
        onClose();

        setTitle("");
        setDescription("");
        setType("");
        setErrors({});
    };

    const handleDialogClose = (_event: {}, reason: "backdropClick" | "escapeKeyDown") => {
        if (reason === "backdropClick") {
            return;
        }
        handleClose();
    };

    const handleSaveClick = async () => {
        const globalServices = getGlobalServices();
        if (globalServices === undefined) {
            return;
        }

        const newErrors = {
            title: !title ? "Please give a title for your survey" : undefined,
            type: !type ? "Please select a survey type" : undefined,
            textMaxLength: parseInt(textMaxLength, 10) <= 0 ? "Max length can't be smaller than 1" : undefined,
            senderId: !senderId ? "Please select a sender" : undefined,
        };
        setErrors(newErrors);

        if (Object.values(newErrors).some((error) => error)) {
            return;
        }

        handleClose();

        let options: SurveyOptions | null = null;

        switch (type) {
        case SurveyType.Choice:
            options = {
                type: "choice",
                values: choiceList.map((choice, index) => ({ key: index, label: choice })),
                isMultiSelect: isMultipleChoice,
            };
            break;
        case SurveyType.Scale:
            options = { type: "scale", from: parseInt(scaleStart, 10), to: parseInt(scaleEnd, 10) };
            break;
        case SurveyType.Text:
            options = { type: "text" };
            if (textMaxLength !== "") {
                options.maxLength = parseInt(textMaxLength, 10);
            }
            break;
        default:
            console.error(`Unhandled survey type "${type}"`);
            return;
        }

        const surveyProps = {
            title, description, type, options, senderId,
        };

        const { dataService } = globalServices;
        if (request?.type === "update") {
            await dataService.updateSurvey(request.itemId, surveyProps);
        } else if (user) {
            await dataService.createSurvey({
                ...surveyProps,
                createdTimestamp: serverTimestamp(),
                creatorUserId: user.uid,
            });
        }
    };

    const dialogTitle = currentSurvey ? "Edit survey" : "Create survey";

    let typeSpecificFields: React.ReactNode;

    switch (type) {
    case SurveyType.Choice:
        typeSpecificFields = (
            <>
                <TypeOptionsTitle>Choice options</TypeOptionsTitle>
                <FormControlLabel
                    control={(
                        <Checkbox
                            color="primary"
                            checked={isMultipleChoice}
                            onChange={({ target }) => setMultipleChoice(target.checked)}
                        />
                    )}
                    label="Multiple choice"
                />

                <TypeOptionsSecondaryTitle>Choices</TypeOptionsSecondaryTitle>
                <AppendableList list={choiceList} setList={setChoiceList} />
            </>
        );
        break;
    case SurveyType.Scale:
        typeSpecificFields = (
            <>
                <TypeOptionsTitle>Scale Options</TypeOptionsTitle>

                <Grid container alignItems="center" justifyContent="space-around">
                    <TextField
                        type="number"
                        variant="outlined"
                        value={scaleStart}
                        disabled
                        onChange={({ target }) => setScaleStart(target.value)}
                        margin="normal"
                        label="From"
                    />
                    <TextField
                        variant="outlined"
                        value={scaleEnd}
                        disabled
                        onChange={({ target }) => setScaleEnd(target.value)}
                        margin="normal"
                        label="To"
                    />
                </Grid>
            </>
        );
        break;
    case SurveyType.Text:
        typeSpecificFields = (
            <>
                <TypeOptionsTitle>Text Options</TypeOptionsTitle>

                <TextField
                    type="number"
                    variant="outlined"
                    value={textMaxLength}
                    error={!!errors.textMaxLength}
                    helperText={errors.textMaxLength}
                    inputProps={{ min: 1 }}
                    onChange={({ target }) => setTextMaxLength(target.value)}
                    margin="normal"
                    label="Maximum Length (empty for unlimited)"
                    fullWidth
                />
            </>
        );
        break;
    default:
        break;
    }

    return (
        <EditorDialog
            open={isOpen}
            onClose={handleDialogClose}
            aria-labelledby="form-dialog-title"
            classes={{ paper: "paper" }}
        >
            <EditorDialogtitle id="form-dialog-title">
                {dialogTitle}
            </EditorDialogtitle>

            <EditorDialogContent>
                <TextField
                    variant="outlined"
                    value={title}
                    onChange={({ target }) => setTitle(target.value)}
                    error={!!errors.title}
                    helperText={errors.title ?? `${title.length}/${MAX_TITLE_LENGTH}`}
                    label="Title"
                    margin="normal"
                    required
                    inputProps={{ maxLength: MAX_TITLE_LENGTH }}
                    autoFocus
                    fullWidth
                />
                <TextField
                    variant="outlined"
                    value={description}
                    onChange={({ target }) => setDescription(target.value)}
                    helperText={errors.text}
                    label="Description"
                    margin="normal"
                    multiline
                    fullWidth
                />

                <FormControl
                    variant="outlined"
                    error={!!errors.type}
                    fullWidth
                    required
                    margin="normal"
                >
                    <InputLabel id="sender-select-label">Survey Type</InputLabel>
                    <Select
                        value={type}
                        onChange={({ target }) => setType(target.value as string)}
                        labelId="type-select-label"
                        label="Survey Type"
                    >
                        {Object.entries(SurveyType).map(([surveyLabel, surveyKey]) => (
                            <MenuItem
                                value={surveyKey}
                                key={surveyKey}
                            >
                                {surveyLabel}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{errors.type}</FormHelperText>
                </FormControl>

                {typeSpecificFields}
                <SelectorsGrid container justifyContent="space-between">
                    <FormControl variant="outlined" error={!!errors.senderId} required>
                        <InputLabel id="sender-select-label">Sender</InputLabel>
                        <Select
                            value={senderId}
                            onChange={({ target }) => setSenderId(target.value as string)}
                            labelId="sender-select-label"
                            label="Sender"
                        >
                            {senders.map(([currentSenderId, sender]) => (
                                <MenuItem
                                    value={currentSenderId}
                                    key={currentSenderId}
                                >
                                    <Selector>
                                        <SenderPictureContainer>
                                            <SenderImage
                                                src={sender.imageUrl}
                                                className="sender-picture"
                                                alt="sender"
                                            />
                                        </SenderPictureContainer>

                                        {sender.name}
                                    </Selector>
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{errors.senderId}</FormHelperText>
                    </FormControl>
                </SelectorsGrid>
            </EditorDialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant="contained">
                    Cancel
                </Button>

                <Button
                    onClick={handleSaveClick}
                    variant="contained"
                    color="primary"
                >
                    Save &amp; Publish
                </Button>
            </DialogActions>
        </EditorDialog>
    );
}

// Styles
const EditorDialog = styled(Dialog)`
    .paper {
        padding: ${({ theme }) => theme.spacing(5)};
        padding-bottom:  ${({ theme }) => theme.spacing(7)};
    }
`;

const EditorDialogtitle = styled(DialogTitle)`
    padding-bottom:  ${({ theme }) => theme.spacing(5)};
    font-size: 1.3rem;
    font-weight: 500;
`;

const EditorDialogContent = styled(DialogContent)`
    padding-right: ${({ theme }) => theme.spacing(3)};
    margin-bottom: ${({ theme }) => theme.spacing(5)};

    & > * {
        margin-bottom: ${({ theme }) => theme.spacing(3)};
    }
`;

const TypeOptionsTitle = styled(Typography)`
    margin-top: ${({ theme }) => theme.spacing(5)};
    margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const TypeOptionsSecondaryTitle = styled(Typography)`
    margin-top: ${({ theme }) => theme.spacing(3)};
    margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const SelectorsGrid = styled(Grid)`
    min-width: 10rem;
    margin-top: ${({ theme }) => theme.spacing(5)};

    & > * {
        flex-grow: 1;

        :first-of-type {
            max-width: 15rem;
            margin-right: ${({ theme }) => theme.spacing(5)};
        }
    }
`;

const Selector = styled("div")`
    display: flex;
    align-items: center;
`;

const SenderPictureContainer = styled("div")`
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    border-radius: 50%;
    overflow: hidden;
`;

const SenderImage = styled("img")`
    width: 1.5rem;
`;
