import { ISheetCounters } from "@resistance-tech/api";

export interface ISheetStatisticsState {
    schemaIdList: Array<string>,
    sheetCounters: {[ schemaId: string]: ISheetCounters | undefined },
    errorMessage: undefined,
    requestInProgress: boolean,
}

export enum SheetStatisticsAction {
    SHEET_SCHEMA_LIST_REQUESTED,
    SHEET_SCHEMA_LIST_RESPONSE,
    SHEET_STATISTICS_REQUESTED,
    SHEET_STATISTICS_RESPONSE,
    SHEET_RECONCILE_STATISTICS_REQUESTED,
    SHEET_RECONCILE_STATISTICS_RESPONSE,
}

export interface SchemaListRequestedAction {
    type: typeof SheetStatisticsAction.SHEET_SCHEMA_LIST_REQUESTED;
}

export interface SchemaListResponseAction {
    type: typeof SheetStatisticsAction.SHEET_SCHEMA_LIST_RESPONSE;
    payload: Array<string>;
}

export interface SheetStatisticsRequestedAction {
    type: typeof SheetStatisticsAction.SHEET_STATISTICS_REQUESTED;
    payload: string;
}

export interface SheetStatisticsResponseAction {
    type: typeof SheetStatisticsAction.SHEET_STATISTICS_RESPONSE;
    payload: {
        schemaId: string,
        counters: ISheetCounters,
    };
}

export interface ReconcileStatisticsAction {
    type: typeof SheetStatisticsAction.SHEET_RECONCILE_STATISTICS_REQUESTED;
}

export interface ReconcileStatisticsResponseAction {
    type: typeof SheetStatisticsAction.SHEET_RECONCILE_STATISTICS_RESPONSE;
    payload: {
        schemaId: string,
        counters: ISheetCounters,
    };
}

export type ActionType = SchemaListRequestedAction
    | SchemaListResponseAction
    | SheetStatisticsRequestedAction
    | SheetStatisticsResponseAction
    | ReconcileStatisticsAction
    | ReconcileStatisticsResponseAction;
