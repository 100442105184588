import { IMembersSyncJobConfig, INationBuilderBackupJobConfig, INationBuilderFixDataJobConfig } from "@resistance-tech/api";
import { toPairs } from "lodash-es";
import MaterialTable from "@material-table/core";
import * as React from "react";
import { useSelector } from "react-redux";
import { selectJobs } from "../../store/selectors";
import { assertNever } from "../../utils/general";

export function JobsOverview() {
    const jobs = useSelector(selectJobs);
    const nationBuilderBackupJobs: [string, INationBuilderBackupJobConfig][] = [];
    const membersSyncJobs: [string, IMembersSyncJobConfig][] = [];
    const nationBuilderFixDataJobs: [string, INationBuilderFixDataJobConfig][] = [];
    // Get the jobs in a type-safe way
    toPairs(jobs).forEach(([id, job]) => {
        switch (job.type) {
        case "nationBuilderBackup":
            nationBuilderBackupJobs.push([id, job]);
            break;
        case "membersSync":
            membersSyncJobs.push([id, job]);
            break;
        case "nationBuilderFixData":
            nationBuilderFixDataJobs.push([id, job]);
            break;
        default:
            assertNever(job, `Unknown job "${JSON.stringify(job)}"`);
        }
    });

    const nationBuilderBackupJobsData = nationBuilderBackupJobs.map(([id, job]) => ({
        id,
        nationSlug: job.nationSlug,
        authEmail: job.authEmail,
    }));

    return (
        <div>
            <div>
                <MaterialTable
                    title="NationBuilder backup jobs"
                    columns={[
                        { title: "ID", field: "id" },
                        {
                            title: "Nation",
                            field: "nationSlug",
                            defaultSort: "asc",
                        },
                        {
                            title: "Account for backup",
                            field: "authEmail",
                        },
                    ]}
                    data={nationBuilderBackupJobsData}
                />
            </div>
            <div>
                <MaterialTable
                    title="Members sync jobs"
                    columns={[
                        { title: "ID", field: "id" },
                    ]}
                    data={membersSyncJobs}
                />
            </div>
            <div>
                <MaterialTable
                    title="NationBuilder fix data jobs"
                    columns={[
                        { title: "ID", field: "id" },
                    ]}
                    data={nationBuilderFixDataJobs}
                />
            </div>
        </div>
    );
}
