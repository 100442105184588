import * as React from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import { getGlobalServices } from "../../services/services";
import { selectSurveys } from "../../store/selectors";
import { IAppState } from "../../store/state";
import { EditorRequest } from "../../types";
import { DeleteDialog } from "./deleteDialog";
import { SurveyEditor } from "./surveyEditor";
import { SurveyView } from "./surveyView";

export function SurveyManager() {
    // Redux state
    const surveys = useSelector((state: IAppState) => selectSurveys(state));

    // Local state
    const [editingRequest, setEditingRequest] = React.useState<EditorRequest | undefined>(undefined);
    const [deletingSurveyId, setDeletingSurveyId] = React.useState<string | undefined>(undefined);

    const handleDeleteDialogClose = (isConfirmed: boolean) => {
        if (isConfirmed) {
            const dataService = getGlobalServices()?.dataService;

            if (dataService && deletingSurveyId) {
                dataService.deleteSurvey(deletingSurveyId);
            }
        }

        setDeletingSurveyId(undefined);
    };

    return (
        <ManagerContainer>
            <SurveyEditor
                request={editingRequest}
                onClose={() => setEditingRequest(undefined)}
            />

            <DeleteDialog
                itemId={deletingSurveyId}
                itemType="survey"
                onClose={handleDeleteDialogClose}
            />

            <CreateButton
                onClick={() => setEditingRequest({ type: "create" })}
                variant="contained"
                color="primary"
                aria-label="Create survey"
            >
                <Add />
                Create survey
            </CreateButton>

            <SurveyCards>
                {surveys.map(([id, survey]) => (
                    <SurveyView
                        key={id}
                        id={id}
                        survey={survey}
                        onEdit={() => setEditingRequest({ type: "update", itemId: id })}
                        onDelete={() => setDeletingSurveyId(id)}
                    />
                ))}
            </SurveyCards>
        </ManagerContainer>
    );
}

// Styles
const ManagerContainer = styled("div")`
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    overflow-y: auto;
`;

const CreateButton = styled(Button)`
    margin: ${({ theme }) => theme.spacing(5, 0)};
`;

const SurveyCards = styled("div")`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;
    max-width: 45rem;
    width: 100%;
    margin: auto;
`;
