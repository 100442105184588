import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { IAddress, IAddressFieldType } from "@resistance-tech/api";
import { styled } from "@mui/material/styles";
import { InputChangeHandler } from "./input.types";

const DEFAULT_ADDRESS = {
    postCode: "",
    city: "",
    street: "",
    number: "",
};

const StyledContainer = styled("div")`
    display: flex;
    width: 100%;
`;

const PostCodeTextField = styled(TextField)`
    padding-right: ${({ theme }) => theme.spacing(1)};
    flex: 0 0 4em;
`;

const CityTextField = styled(TextField)`
    padding-right: ${({ theme }) => theme.spacing(1)};
    flex: 1 0 6em;
`;

const StreetAutocomplete = styled(Autocomplete)`
    padding-right: ${({ theme }) => theme.spacing(1)};
    flex: 1 1 8em;
`;

const NumberTextField = styled(TextField)`
    flex: 0 0 3em;
`;

const makeStreetUppercase = (
    street: string | undefined, setState: React.Dispatch<React.SetStateAction<{
        address: any;
        recommendedStreets: never[];
    }>>,
) => {
    if (!street || street.length === 0) {
        return;
    }

    const uppercaseStreet = street.charAt(0).toUpperCase() + street.slice(1);
    if (street !== uppercaseStreet) {
        setState((prevState) => ({
            ...prevState,
            address: {
                ...prevState.address,
                street: uppercaseStreet,
            },
        }));
    }
};

export function AddressInput({
    initialValue = DEFAULT_ADDRESS,
    inputChangeHandler = () => {},
}
: {
    initialValue?: IAddress,
    inputChangeHandler?: InputChangeHandler,
}) {
    const [state, setState] = useState({ address: initialValue, recommendedStreets: [] });
    const {
        city, street, postCode, number: streetNumber,
    } = state.address;

    const handlePostCodeChange = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        event.preventDefault();
        const { value: newPostCode } = event.target;

        // Update the value in state
        handleChange("postCode", newPostCode);

        // Update the rest of the address components based on the post code
        if (newPostCode === null || newPostCode.length !== 4 || Number.isNaN(parseInt(newPostCode, 10))) {
            return;
        }

        const fileId = parseInt(newPostCode[0], 10);
        if (Number.isNaN(fileId) || fileId < 0 || fileId > 9) {
            return;
        }

        const postCodeFile = (await import(`../../data/post_codes/pc_${fileId}000.json`)).default;
        if (postCodeFile[newPostCode] !== undefined) {
            setState((prevState) => ({
                recommendedStreets: postCodeFile[newPostCode].streets,
                address: {
                    ...prevState.address,
                    postCode: newPostCode,
                    city: postCodeFile[newPostCode].city,
                },
            }));
        } else {
            console.error(`unknown postcode: ${newPostCode}`);
        }
    };

    const handleChange = (
        field: IAddressFieldType,
        newValue: string,
    ) => {
        setState((prevState) => {
            const newAddress = { ...prevState.address };
            newAddress[field] = newValue;
            return {
                ...prevState,
                address: newAddress,
            };
        });
    };

    return (
        <StyledContainer>
            <PostCodeTextField
                autoComplete="off"
                value={postCode}
                onChange={(e) => handlePostCodeChange(e)}
                onBlur={() => inputChangeHandler(state.address)}
            />
            <CityTextField
                autoComplete="off"
                inputProps={{ style: { textTransform: "capitalize" } }}
                value={city}
                onChange={(e) => handleChange("city", e.target.value)}
                onBlur={() => inputChangeHandler(state.address)}
            />

            <StreetAutocomplete
                options={state.recommendedStreets
                    .filter((str: string) => !street || str.toLowerCase().includes(street.toLowerCase()))}
                freeSolo
                autoSelect
                autoComplete
                value={street}
                onInputChange={(_, selectedStreet) => handleChange("street", selectedStreet)}
                renderInput={(params) => (
                    <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        onBlur={() => inputChangeHandler(state.address)}
                        onKeyUp={() => makeStreetUppercase(state.address?.street, setState)}
                    />
                )}
            />
            <NumberTextField
                autoComplete="off"
                value={streetNumber}
                onChange={(e) => handleChange("number", e.target.value)}
                onBlur={() => inputChangeHandler(state.address)}
            />
        </StyledContainer>
    );
}
