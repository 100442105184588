import React, { useCallback, useEffect, useState } from "react";
import OpenSeadragon from "openseadragon";

import "./imageViewer.scss";

interface IImageViewerProps {
    id: string;
    image: string;
}

const initSeaDragon = ({ id, image } : IImageViewerProps) => OpenSeadragon({
    id,
    tileSources: {
        type: "image",
        url: image,
    },
    zoomInButton: "zoom-in",
    zoomOutButton: "zoom-out",
    homeButton: "home",
    fullPageButton: "full-page",
    rotateRightButton: "cw",
    rotateLeftButton: "ccw",
    showNavigator: true,
    showRotationControl: true,
    constrainDuringPan: true,
    navigatorPosition: "BOTTOM_RIGHT",
    navigatorAutoResize: true,
    navigatorDisplayRegionColor: "green",
    navigatorBackground: "lightgrey",
    navigatorMaintainSizeRatio: true,
    navigatorRotate: true,
    visibilityRatio: 0.5,
    gestureSettingsMouse: {
        scrollToZoom: false,
    },
    zoomPerClick: 1.2,
});

const zoomToWidth = (viewer: OpenSeadragon.Viewer) => {
    if (!viewer) {
        return;
    }

    const tiledImage = viewer.world.getItemAt(0);
    const dimension = tiledImage.getContentSize();
    const imageRect = getImageRect(dimension, viewer.viewport.getRotation());
    const viewportRect = tiledImage.imageToViewportRectangle(imageRect);
    viewer.viewport.fitBounds(viewportRect, true);
};

const getImageRect = (dimension: OpenSeadragon.Point, rotation: number) => {
    const dim = rotation % 180 === 90 ? dimension.y : dimension.x;
    const origin: OpenSeadragon.Point = rotation > 90 ? dimension : new OpenSeadragon.Point(0, 0);
    return new OpenSeadragon.Rect(origin.x, origin.y, dim, 0, rotation);
};

export function ImageViewer(props: IImageViewerProps) {
    const [viewer, setViewer] = useState({} as OpenSeadragon.Viewer);
    const { id, image } = props;

    const safeSetViewer = useCallback(() => setViewer(initSeaDragon({ id, image })), [id, image]);
    useEffect(() => {
        safeSetViewer();
    }, [safeSetViewer]);

    if (!viewer) {
        return (<div />);
    }

    return (
        <div className="resizableImageViewer">
            <div
                id={id}
                className="image"
                onContextMenu={() => false}
            />

            <div className="toolbar">
                <span className="actions-container">
                    <a id="zoom-in" href="#zoom-in" className="toolbar-action">Nagyítás</a>
                    <a id="zoom-out" href="#zoom-out" className="toolbar-action">Kicsinyítés</a>
                    <a
                        href="#zoom-width"
                        onClick={() => zoomToWidth(viewer)}
                    >
                        Teljes szélesség
                    </a>
                    <a id="ccw" href="#ccw" className="toolbar-action">Forgatás jobbra</a>
                    <a id="cw" href="#cw" className="toolbar-action">Forgatás balra</a>
                    <a id="home" href="#home" className="toolbar-action">Alapértelmezett</a>
                    <a id="full-page" href="#full-page" className="toolbar-action">Teljes képernyő</a>
                </span>
            </div>
        </div>
    );
}
