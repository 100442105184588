import * as React from "react";
import { styled } from "@mui/material/styles";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import { IFirestorePost } from "@resistance-tech/api";

export type IPostDeleterEditablePostProps = Pick<IFirestorePost, "title" | "description" | "url">;
export type IPostDeleterType = "edit" | "create";

interface IProps {
    itemId: string | undefined;
    itemType: string;
    onClose: (isConfirm: boolean) => void;
}

export function DeleteDialog({ itemId, itemType, onClose }: IProps) {
    return (
        <StyledDialog
            open={itemId !== undefined}
            onClose={onClose}
            classes={{ paper: "paper" }}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">{`Delete ${itemType}`}</DialogTitle>

            <DeleteDialogContent>
                <DialogContentText>
                    {`Are you sure you want to delete this ${itemType}? This cannot be undone.`}
                </DialogContentText>
            </DeleteDialogContent>

            <DialogActions>
                <Button onClick={() => onClose(false)} variant="contained">
                    Cancel
                </Button>

                <DeleteButton onClick={() => onClose(true)} variant="contained">
                    Delete forever
                </DeleteButton>
            </DialogActions>
        </StyledDialog>
    );
}

// Styles
const StyledDialog = styled(Dialog)`
    .paper {
        padding: ${({ theme }) => theme.spacing(3, 5, 7, 3)};
    }
`;

const DeleteDialogContent = styled(DialogContent)`
    margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

const DeleteButton = styled(Button)`
    color: ${({ theme }) => theme.palette.error.contrastText};
    background-color: ${({ theme }) => theme.palette.error.main};

    &:hover {
        color: ${({ theme }) => theme.palette.error.contrastText};
        background-color: ${({ theme }) => theme.palette.error.dark};
    }
`;
