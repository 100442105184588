import React, { useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";

import SettingsIcon from "@mui/icons-material/Settings";

import { IFormSchema, ISheetPreferencesSubmitMode, SHEET_PREFERENCES_SUBMIT_MODES } from "@resistance-tech/api";
import { DataService } from "../../services/dataService";
import { FunctionsService } from "../../services/functionsService";
import { assertNever } from "../../utils/general";

const useStyles = makeStyles((theme: Theme) => createStyles({
    button: {
        height: "100%",
        border: 0,
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: "30em",
        height: "20em",

    },

    closeButton: {
        float: "right",
    },
}));

function getSubmitModeLabel(mode: ISheetPreferencesSubmitMode) {
    switch (mode) {
    case "process-new-sheets":
        return "Normál (új ívek)";
    case "review-failed-submissions":
        return "Nation Builder által hibásnak jelzett ívek felülvizsgálata";
    case "review-reported-sheets":
        return "Hibásnak jelölt ívek felülvizsgálata";
    default:
        assertNever(mode, `Unknown submit mode type ${mode}`);
        return "<unknown>";
    }
}

export function SheetPreferencesModal({
    dataService, functionsService, hasAdminRole, onSave,
}
    : { dataService: DataService, functionsService: FunctionsService, hasAdminRole: boolean, onSave: () => void }) {
    const [open, setOpen] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const [schemas, setSchemas] = useState<IFormSchema[]>([]);
    const [selectedSchema, setSelectedSchema] = useState<string>("");
    const [selectedSubmitMode, setSelectedSubmitMode] = useState<ISheetPreferencesSubmitMode>("process-new-sheets");
    const classes = useStyles();

    useEffect(() => {
        dataService.getSchemas().then((formSchemas) => setSchemas(formSchemas));
        dataService.getSheetPreferences().then(
            (userConfig) => {
                setSelectedSchema(userConfig?.sheetPreferences?.preferredSchema ?? "");
                setSelectedSubmitMode(userConfig?.sheetPreferences?.submitMode ?? "process-new-sheets");
            },
        );
    }, [dataService, setSchemas]);

    const handleSave = async () => {
        setSaving(true);
        await functionsService.saveSheetPreferences({
            preferredSchema: selectedSchema,
            submitMode: selectedSubmitMode,
        });
        setSaving(false);
        setOpen(false);
        onSave();
    };
    return (
        <>
            <button
                className={classes.button}
                color="primary"
                type="button"
                onClick={() => setOpen(true)}
            >
                <SettingsIcon />
            </button>

            <Dialog open={open}>
                <DialogTitle>
                    Beállítások
                </DialogTitle>
                <DialogContent>
                    <div className={classes.modal}>
                        <Grid container direction="column">
                            <Grid item key="schema-selector">
                                <FormControl fullWidth margin="normal">
                                    <InputLabel id="schema-selector-label">Séma/Kampány</InputLabel>
                                    <Select
                                        labelId="schema-selector-label"
                                        fullWidth
                                        value={selectedSchema ?? ""}
                                        onChange={(event) => setSelectedSchema(event.target.value as string)}
                                        inputProps={{
                                            name: "Schema",
                                            id: "schema",
                                        }}
                                    >
                                        <MenuItem key="none" value="">
                                            <em>Nincs preferált kampány</em>
                                        </MenuItem>
                                        {schemas.map((schema) => (
                                            <MenuItem
                                                key={schema.name}
                                                value={schema.name}
                                            >
                                                {schema.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item key="submitmode-selector">
                                <FormControl fullWidth>
                                    <InputLabel id="submitmode-selector-label">Ívek típusa</InputLabel>
                                    <Select
                                        labelId="submitmode-selector-label"
                                        fullWidth
                                        value={selectedSubmitMode}
                                        onChange={
                                            (event) => setSelectedSubmitMode(
                                            event.target.value as ISheetPreferencesSubmitMode,
                                            )
                                        }
                                        inputProps={{
                                            name: "Schema",
                                            id: "schema",
                                        }}
                                    >
                                        {SHEET_PREFERENCES_SUBMIT_MODES
                                            .filter((submitMode) => hasAdminRole || submitMode !== "review-reported-sheets")
                                            .map((submitMode) => (
                                                <MenuItem
                                                    key={submitMode}
                                                    value={submitMode}
                                                >
                                                    {getSubmitModeLabel(submitMode)}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    { saving && (<CircularProgress size={20} />)}
                    {!saving && (
                        <>
                            <Button onClick={() => setOpen(false)}>Mégse</Button>
                            <Button onClick={handleSave} color="primary">Mentés</Button>
                        </>
                    )}

                </DialogActions>
            </Dialog>
        </>
    );
}
