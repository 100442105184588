import React from "react";
import { ISignatureDataWithImportFailureReason } from "@resistance-tech/api";
import ErrorIcon from "@mui/icons-material/Error";
import { Tooltip } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => createStyles({
    container: {
        display: "flex",
        height: "100%",
        alignItems: "center",
        padding: 10,
    },
}));

export function ImportErrorReasonMarker({
    value = undefined,
}
: {
    value?: ISignatureDataWithImportFailureReason["reason"],
}) {
    const classes = useStyles();
    if (value == null) {
        return <div>-</div>;
    }
    return (
        <div className={classes.container}>
            <Tooltip title={value.failureMessage}>
                <ErrorIcon />
            </Tooltip>
        </div>
    );
}
