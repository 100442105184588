import React, { useEffect, useState } from "react";

import { TextField } from "@mui/material";

export function ListControl<T>({
    itemToText, textToItem, onChange, errorText = null, items = [], label,
}
    : {
        itemToText: (item: T) => string,
        textToItem: (text: string) => T,
        onChange: (list: T[]) => void,
        errorText: string | null,
        items: T[],
        label: string
    }) {
    const [text, setText] = useState(items.map(itemToText).join());

    useEffect(() => {
        setText(items.map(itemToText).join());
    }, [setText, items, itemToText]);

    const textToList = () => text.split(",")
        .map((item) => item.trim())
        .filter((item) => !!item) // filter empty, undefined, null...
        .map(textToItem);

    return (
        <TextField
            autoComplete="none"
            value={text}
            label={label}
            onChange={(e) => setText(e.target.value)}
            onBlur={() => onChange(textToList())}
            onKeyUp={(e) => {
                if (e.keyCode === 13) {
                    onChange(textToList());
                }
            }}
            helperText={errorText}
            error={!!errorText}
        />
    );
}
