import React, { useEffect, useState } from "react";
import { serverTimestamp } from "firebase/firestore";

import {
    Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField,
} from "@mui/material";

import {
    IFieldType, IFormSchema,
} from "@resistance-tech/api";
import { SchemaSelectControl } from "./schemaSelectControl";
import { ListControl } from "./listControl";

import { getGlobalServices } from "../../../services/services";

import "./schemaFormDialog.scss";

const FIELDS = ["address", "singlePostCode", "name", "phoneNumber", "emailAddress", "consentToGoal", "consentToEmail", "consentToPhone", "consentToContact", "comment"];

export function SchemaFormDialog({ initialSchema, open, onClose }
    : {initialSchema: IFormSchema | null, open: boolean, onClose: (isSave: boolean) => void}) {
    const [selectedSchema, setSelectedSchema] = useState<IFormSchema | null>(null);
    const [schemaName, setSchemaName] = useState(initialSchema?.name);
    const [defaultNumberOfRows, setDefaultNumberOfRows] = useState<number>(initialSchema?.defaultNumberOfRows ?? 5);
    const [fields, setFields] = useState<IFieldType[]>(initialSchema?.fields ?? []);
    const [fieldsError, setFieldsError] = useState<string | null>(null);
    const [tags, setTags] = useState<string[]>(initialSchema?.nationBuilderTags ?? []);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        setSelectedSchema(initialSchema);
        setSchemaName("");
        setDefaultNumberOfRows(initialSchema?.defaultNumberOfRows ?? 5);
        setFields(initialSchema?.fields ?? []);
        setTags(initialSchema?.nationBuilderTags ?? []);
    }, [initialSchema, setFields, setTags, setDefaultNumberOfRows, setSchemaName, setSelectedSchema]);

    const originalSchemaChangeHandler = (_: string | null, schema: IFormSchema | null) => {
        setSelectedSchema(schema);
    };

    const handleCopy = () => {
        if (!selectedSchema) {
            return;
        }

        setDefaultNumberOfRows(selectedSchema.defaultNumberOfRows);
        setFields(selectedSchema.fields);
        setTags(selectedSchema.nationBuilderTags);
    };

    const handleSave = () => {
        if (fieldsError || schemaName === "" || !schemaName) {
            return;
        }

        const newSchema: IFormSchema = {
            name: schemaName,
            defaultNumberOfRows,
            fields,
            nationBuilderTags: tags,
            createdTime: serverTimestamp(),
            status: "active",
        };
        setSaving(true);
        getGlobalServices()?.dataService.createSchema(schemaName, newSchema).then(() => {
            setSaving(false);
            onClose(true);
        });
    };

    const validateAndSetFields = (items: IFieldType[]) => {
        const invalidFields = items.filter((item) => !FIELDS.includes(item));

        const errorText = invalidFields.length === 0 ? null : `Hibás oszlopok: ${invalidFields.join(", ")}`;
        setFieldsError(errorText);
        setFields(items);
    };

    return (
        <Dialog open={open} maxWidth="xl">
            <DialogTitle>Új séma</DialogTitle>
            <DialogContent>
                <div className="schema-container">
                    <div className="row">
                        <SchemaSelectControl
                            onChange={originalSchemaChangeHandler}
                            selectFirst={false}
                            label="Original Schema"
                            className="flex-item-fill"
                            initialSchema={selectedSchema?.name ?? ""}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCopy}
                        >
                            Másol

                        </Button>
                    </div>
                    <Divider className="row-divider" />

                    <TextField
                        className="flex-item-fill"
                        label="Kampány név"
                        required
                        value={schemaName}
                        onChange={(e) => setSchemaName(e.target.value)}
                        error={schemaName === ""}
                    />

                    <TextField
                        className="flex-item-fill"
                        label="Sorok száma"
                        type="number"
                        InputProps={{ inputProps: { min: 1 } }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={defaultNumberOfRows}
                        onChange={(e) => setDefaultNumberOfRows(parseInt(e.target.value, 10))}
                    />

                    <ListControl
                        label="Oszlopok"
                        itemToText={(item) => item}
                        textToItem={(item) => item as IFieldType}
                        items={fields}
                        onChange={validateAndSetFields}
                        errorText={fieldsError}
                    />

                    <ListControl
                        label="Nation Builder Tag-ek"
                        itemToText={(item) => item}
                        textToItem={(item) => item as string}
                        items={tags}
                        onChange={setTags}
                        errorText={null}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                { saving && (<CircularProgress />)}
                {!saving && (
                    <>
                        <Button onClick={() => onClose(false)}>Mégse</Button>
                        <Button
                            onClick={handleSave}
                            color="primary"
                            disabled={!!fieldsError || schemaName === ""}
                        >
                            Mentés

                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>

    );
}
