import * as React from "react";
import { styled } from "@mui/material/styles";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Typography,
} from "@mui/material";
import {
    ArrowDownward,
    ArrowUpward,
    CloudDownloadOutlined,
    Delete,
    Edit,
} from "@mui/icons-material";
import {
    IFirestoreSurvey,
    IFirestoreSurveyMultipleResponse,
    IFirestoreSurveyOptionsChoice,
    IFirestoreSurveyResponse,
} from "@resistance-tech/api";
import { CSVLink } from "react-csv";

import { SurveyOptionsView } from "./surveyOptionsView";
import { getGlobalServices } from "../../services/services";
import { SurveyResponseBreakdownView } from "./surveyResponseBreakdownView";

interface IProps {
    id: string,
    survey: IFirestoreSurvey;
    onEdit: () => void;
    onDelete: () => void;
}

export function SurveyView({
    id,
    survey,
    onEdit,
    onDelete,
}: IProps) {
    const [csvData, setCsvData] = React.useState<undefined | object[]>(undefined);
    const [surveyResponses, setSurveyResponses] = React.useState<undefined | IFirestoreSurveyResponse[]>(undefined);
    const [showResponseBreakdown, setShowResponseBreakdown] = React.useState<boolean>(false);

    const responsesHeaders = [
        {
            label: "Kérdés",
            key: "question",
        },
        {
            label: "Válasz(ok)",
            key: "answers",
        },
        {
            label: "Válasz időpontja",
            key: "date",
        },
    ];

    const {
        title,
        description,
        createdTimestamp,
        options,
    } = survey;
    const createdDate = createdTimestamp.toDate();

    const loadSurveyResponses = async (): Promise<IFirestoreSurveyResponse[]> => {
        // Don't load again
        if (surveyResponses !== undefined) {
            return surveyResponses;
        }

        // First get the responses for the current survey
        const globalServices = getGlobalServices();
        if (globalServices === undefined) {
            return [];
        }
        const { dataService } = globalServices;
        const responses = await dataService.getSurveyAnswers(id);
        const responseArray = Object.keys(responses)
            .map((key) => responses[key]);
        setSurveyResponses(responseArray);
        return responseArray;
    };

    // Transform response key values to the actual response string options
    const mapMultipleChoiceKeysToLabels = (response: IFirestoreSurveyMultipleResponse) => {
        const responseOptions = (survey.options as IFirestoreSurveyOptionsChoice)?.values;
        if (responseOptions === undefined) {
            return null;
        }

        return response.values.map((vKey) => responseOptions.find((ro) => ro.key === vKey)?.label)
            .join(", ");
    };

    // Create a more user friendly response string for different types of surveys
    const prepareResponseValue = (response: IFirestoreSurveyResponse) => {
        switch (response.value.type) {
        case "single":
            return response.value.value;
        case "multiple":
            return mapMultipleChoiceKeysToLabels(response.value);
        case "text":
            return response.value.text;
        case undefined:
            if (response.value.text !== undefined) {
                return response.value.text;
            }
            if (response.value.value !== undefined) {
                return response.value.value;
            }
            if (response.value.values !== undefined) {
                // Ugly casting, but now we are sure that response.value matches our interface
                return mapMultipleChoiceKeysToLabels((response.value as unknown) as IFirestoreSurveyMultipleResponse);
            }
            break;
        default:
            break;
        }

        return null;
    };

    const handleDownloadAnswers = async () => {
        const responses = await loadSurveyResponses();
        if (responses === undefined) {
            return;
        }

        setCsvData(undefined);
        // Now let's prepare the response data for our .csv format
        const data: object[] = responses.map((response) => {
            const question = survey.title;
            const date = new Date(response.respondedTimestamp.seconds * 1000).toLocaleString();
            const answers = prepareResponseValue(response);

            return {
                date,
                question,
                answers,
            };
        });

        setCsvData(data);

        // Because <CSVDownload> doesn't use the provided filename, we have to use the clickable component
        // and click it manually: https://github.com/react-csv/react-csv/pull/137
        document.getElementById(`download-responses-${id}`)
            ?.click();
    };

    return (
        <SurveyCard>
            <CardContent>
                <Title variant="h6" gutterBottom>
                    {title}
                </Title>

                <StyledDate variant="subtitle1" color="textSecondary" gutterBottom>
                    {createdDate.toLocaleString()}
                </StyledDate>

                <Description component="p">
                    {description ?? "-"}
                </Description>

                <SurveyOptionsView options={options} />
            </CardContent>

            <SurveyCardActions>
                <Button
                    onClick={async () => {
                        await loadSurveyResponses();
                        setShowResponseBreakdown(!showResponseBreakdown);
                    }}
                    startIcon={showResponseBreakdown ? <ArrowUpward /> : <ArrowDownward />}
                >
                    Show results
                </Button>

                <Button onClick={handleDownloadAnswers} startIcon={<CloudDownloadOutlined />}>
                    Download Answers
                </Button>
                {csvData !== undefined
                && (
                    <CSVLink
                        style={{ display: "none" }}
                        id={`download-responses-${id}`}
                        data={csvData}
                        headers={responsesHeaders}
                        separator=";"
                        enclosingCharacter="`"
                        filename={`${survey.title} válaszok.csv`}
                    />
                )}

                <Button onClick={onEdit} startIcon={<Edit />}>
                    Edit
                </Button>

                <Button onClick={onDelete} startIcon={<Delete />}>
                    Delete
                </Button>
            </SurveyCardActions>

            {showResponseBreakdown && (
                <CardContent>
                    <SurveyResponseBreakdownView
                        responses={surveyResponses}
                        survey={survey}
                    />
                </CardContent>
            )}
        </SurveyCard>
    );
}

// Styles
const SurveyCard = styled(Card)`
  margin: 20px 0 0 0;
  padding: ${({ theme }) => theme.spacing(2, 4)};
`;

const SurveyCardActions = styled(CardActions)`
  justify-content: flex-end;

  & > * {
    padding-left: ${({ theme }) => theme.spacing(5)};
    padding-right: ${({ theme }) => theme.spacing(5)};

    :not(:first-of-type) {
      margin-left: ${({ theme }) => theme.spacing(3)};
    }
  }
`;

const Title = styled(Typography)`
  font-weight: 500;

  & > a {
    color: black;
  }
`;

const StyledDate = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(5)};
`;

const Description = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(5)};
` as typeof Typography;
