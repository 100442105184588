import React, {
    useContext, useEffect, useReducer, useState,
} from "react";
import { styled } from "@mui/material/styles";

import {
    Button, TextField, Tooltip,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import SplitPane from "react-split-pane";

import {
    IAddress, IName, ISheetPreferences, ISignatureData, ISignatureDataWithImportFailureReason,
} from "@resistance-tech/api";
import { DataService } from "../../../services/dataService";
import { FunctionsService } from "../../../services/functionsService";
import { ComponentStateContext, DEFAULT_STATE, reducer } from "./sheetDigitalization.state";

import {
    SheetState,
} from "./sheetDigitalization.types";
import { SheetEditTable } from "../sheetEditTable";
import { ImageViewer } from "../imageViewer/imageViewer";
import { SheetPreferencesModal } from "../sheetPreferencesModal";

const isEmptySignature = (signature: ISignatureData) => isEmptyName(signature.name)
    && isEmptyAddress(signature.address)
    && !signature.consentToPhone
    && !signature.consentToGoal
    && !signature.consentToEmail
    && (!signature.emailAddress || signature.emailAddress === "")
    && (!signature.phoneNumber || signature.phoneNumber === "");

const isEmptyAddress = (address?: IAddress) => !address
    || ((!address.postCode || address.postCode === "")
        && (!address.city || address.city === "")
        && (!address.street || address.street === "")
        && (!address.number || address.number === ""));
const isEmptyName = (name?: IName) => !name
    || ((!name.nameTitle || name.nameTitle === "")
        && (!name.firstName || name.firstName === "")
        && (!name.lastName || name.lastName === ""));

export function SheetDigitalization({
    dataService, functionsService, hasAdminRole, sheetPreferences,
}
    : {
        dataService: DataService,
        functionsService: FunctionsService, hasAdminRole: boolean, sheetPreferences: ISheetPreferences }) {
    const [currentState, dispatch] = useReducer(reducer, DEFAULT_STATE);

    useEffect(() => {
        const requestSheet = async () => {
            try {
                const { submission, action } = currentState;
                const data = submission.data
                    .filter((signature: ISignatureData) => !isEmptySignature(signature))
                    .map((signature: ISignatureDataWithImportFailureReason) => (
                        { ...signature, reason: undefined } // remove any previous failure reasons
                    ));
                const completeCurrent = submission.sheet !== "" && data.length > 0;

                const assignNew = true;
                const actionWithCurrentIfNotReported = completeCurrent ? { type: "complete" as const, submission: { ...submission, data } } : undefined;
                const actionWithCurrent = action === "report" || action === "archive" ? { type: action } : actionWithCurrentIfNotReported;
                const { imageUrl, docId, previousSubmission } = await functionsService.manageSheetDocument({
                    actionWithCurrent,
                    assignNew,
                });

                if (completeCurrent || action === "report" || action === "archive") {
                    dispatch({ type: SheetState.SHEET_COMPLETED });
                }

                if (!imageUrl || !docId) {
                    dispatch({ type: SheetState.SHEET_INVALID, payload: { message: "Nincs hozzád rendelt beviteli ív!" } });
                    return;
                }

                const schema = await dataService.findSheetSchema(docId);
                if (!schema) {
                    dispatch({ type: SheetState.SHEET_INVALID, payload: { message: `formSchemaId of assigned sheet is undefined. Sheet: ${docId}` } });
                    return;
                }

                dispatch({
                    type: SheetState.SHEET_ASSIGNED,
                    payload: {
                        assignedSheetId: docId,
                        schema,
                        imageUrl,
                        previousSubmission: previousSubmission ?? undefined,
                    },
                });
            } catch (error) {
                dispatch({ type: SheetState.SHEET_INVALID, payload: { message: "Hiba történt!" } });
            }
        };
        if (currentState.requestInProgress) {
            requestSheet();
        }
    }, [currentState, functionsService, dataService]);

    const {
        schema, submission, imageUrl, errorMessage, requestInProgress,
    } = currentState;

    if (requestInProgress) {
        return (
            <CenterDiv>
                <CircularProgress />
            </CenterDiv>
        );
    }

    const sheetPreferencesModal = (
        <SheetPreferencesModal
            dataService={dataService}
            functionsService={functionsService}
            hasAdminRole={hasAdminRole}
            onSave={() => dispatch({ type: SheetState.SHEET_RESET })}
        />
    );

    if (errorMessage || !schema || !submission.sheet) {
        return (
            <ComponentStateContext.Provider value={{ state: currentState, dispatch }}>
                <p>{errorMessage}</p>
                <CenterDiv>
                    <Button onClick={() => dispatch({ type: SheetState.SHEET_REQUESTED })}>Új ívet kérek</Button>
                    {sheetPreferencesModal}
                </CenterDiv>
            </ComponentStateContext.Provider>
        );
    }

    return (
        <ComponentStateContext.Provider value={{ state: currentState, dispatch }}>
            <SheetContainer>
                <TitleContainer>
                    <CenterDiv>
                        {sheetPreferencesModal}
                        Kampány:
                        {" "}
                        {schema.name}
                    </CenterDiv>
                    <span>
                        Azonosító:
                        {" "}
                        {submission.sheet}
                    </span>
                </TitleContainer>
                <SplitPaneParent>
                    <SplitPane split="horizontal" minSize="50%" defaultSize="50%" style={{ position: "relative" }}>
                        <SheetImagePane>
                            {imageUrl && (<ImageViewer id="test" image={imageUrl} />)}
                        </SheetImagePane>
                        <SheetInputPane>
                            <SheetDigitalizationHeader
                                initialSheetId={submission.sheetId}
                                sheetPreferences={sheetPreferences}
                            />
                            <SheetEditTable schema={schema} submission={submission} dispatch={dispatch} />
                        </SheetInputPane>
                    </SplitPane>
                </SplitPaneParent>
            </SheetContainer>
        </ComponentStateContext.Provider>
    );
}

const validateSheetId = (value: string) => {
    if (!value || value.trim() === "") {
        return false;
    }

    const expr = /^21[0-9]{4}-[A-Z0-9]{3}-[A-Z0-9]{2}-[A-Z0-9]{3}-[0-9]{3}$/;
    return expr.test(value);
};

function SheetDigitalizationHeader({ initialSheetId, sheetPreferences }: {
        initialSheetId: string | undefined,
        sheetPreferences: ISheetPreferences | undefined
    }) {
    const { dispatch } = useContext(ComponentStateContext);
    const [sheetId, setSheetId] = useState(initialSheetId ?? "");
    const [isSheetIdValid, setSheetIdValid] = useState(validateSheetId(initialSheetId ?? ""));

    const sheetIdFormat = "210605-XXX-XX-XXX-001";

    return (
        <StyledSheetHeader>
            <Tooltip title={`Formátum: ${sheetIdFormat}`}>
                <TextField
                    type="text"
                    label="Azonosító"
                    size="small"
                    helperText={`( ${sheetIdFormat} )`}
                    value={sheetId}
                    error={!isSheetIdValid}
                    onChange={(e) => {
                        e.target.value = e.target.value.toUpperCase();
                        setSheetId(e.target.value);
                        setSheetIdValid(validateSheetId(e.target.value));
                    }}
                    onBlur={() => {
                        setSheetIdValid(validateSheetId(sheetId));
                        dispatch({ type: SheetState.SHEET_ID_CHANGED, payload: sheetId });
                    }}
                />
            </Tooltip>
            <StyledActionsContainer>
                <AddButton onClick={() => dispatch({ type: SheetState.SIGNATURE_ADDED })}>Új sor</AddButton>
                <CompleteButton
                    onClick={() => dispatch({ type: SheetState.SHEET_REQUESTED })}
                    disabled={sheetId === ""}
                >
                    Küldés
                </CompleteButton>
                {sheetPreferences?.submitMode !== "review-reported-sheets" && (
                    <ErrorButton onClick={() => dispatch({ type: SheetState.SHEET_REPORTED })}>
                        Hibás
                    </ErrorButton>
                )}
                {sheetPreferences?.submitMode === "review-reported-sheets" && (
                    <ErrorButton onClick={() => dispatch({ type: SheetState.SHEET_ARCHIVED })}>
                        Archiválás
                    </ErrorButton>
                )}

            </StyledActionsContainer>
        </StyledSheetHeader>
    );
}

const TitleContainer = styled("div")`
    margin-left: 1rem;
    margin-right: 1rem;
    
    display: flex;
    justify-content: space-between;

`;

const SheetContainer = styled("div")`
    display: flex;
    flex-flow: column;
    padding: 0px;
    height: 100%;
`;

const SheetInputPane = styled("div")`
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
`;

const SheetImagePane = styled("div")`
    width: 100%;
    margin: 0 1em 0 1em;
`;

const StyledSheetHeader = styled("div")`
    display: flex;
    align-items: center;
    height: 5em;
    background: white;
`;

const StyledActionsContainer = styled("div")`
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const CompleteButton = styled(Button)`
    background: lightgreen;
    margin-right: 1em;
`;

const AddButton = styled(Button)`
    background: lightblue;
    margin-right: 1em;
`;

const ErrorButton = styled(Button)`
    background: red;
`;

const CenterDiv = styled("div")`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SplitPaneParent = styled("div")`
    flex-grow: 1;
`;
