/* eslint-disable react/jsx-props-no-spreading */
import React from "react";

import LinearProgress, { LinearProgressProps } from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export interface FileProgress {
    id: string,
    fileName: string,
    totalBytes?: number,
    bytesTransferred?: number,
    state: string
}

export function UploadProgressBar({ fileProgressList } : {fileProgressList: FileProgress[]}) {
    return (
        <div>
            {fileProgressList.map((fileProgress) => (
                <UploadProgressBarElement key={fileProgress.id} fileProgress={fileProgress} />
            ))}
        </div>
    );
}

function UploadProgressBarElement({ fileProgress } : {fileProgress: FileProgress}) {
    const progress = ((fileProgress.bytesTransferred ?? 0) / (fileProgress.totalBytes ?? 1)) * 100;
    const error = fileProgress.state === "FAILED";
    return (
        <div>
            <LinearProgressWithLabel value={progress} />
            <Box display="flex" alignItems="center" component="span" m={2}>
                {fileProgress.fileName}
                {!error && (
                    <FileSizeLabel
                        bytesTransferred={fileProgress.bytesTransferred}
                        totalBytes={fileProgress.totalBytes}
                    />
                )}
                {error && (<span className="error">Failed</span>)}
            </Box>
        </div>
    );
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">
                    {`${Math.round(
                        props.value,
                    )}%`}

                </Typography>
            </Box>
        </Box>
    );
}

const humanFileSize = (bytes: number) : string => {
    const divider = 1024;

    const units = ["B", "kB", "MB", "GB"];
    let unitIndex = 0;
    let sizeInUnit = bytes;

    while (sizeInUnit >= divider && unitIndex < units.length - 1) {
        sizeInUnit /= divider;
        ++unitIndex;
    }

    return `${sizeInUnit.toFixed(1)} ${units[unitIndex]}`;
};

function FileSizeLabel({ bytesTransferred, totalBytes }
    : {bytesTransferred: number | undefined, totalBytes: number | undefined}) {
    if (!bytesTransferred || !totalBytes) {
        return (<Box component="span" paddingLeft="1em">...</Box>);
    }

    const transferredLabel = humanFileSize(bytesTransferred);
    const totalLabel = humanFileSize(totalBytes);

    return (
        <>
            <Box component="span" paddingLeft="1em">{transferredLabel}</Box>
            <Box component="span" paddingLeft=".25em" paddingRight=".25em">/</Box>
            <Box component="span">{totalLabel}</Box>
        </>
    );
}
