import {
    Button, DialogActions, Grid,
    Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FirestoreAdminUserRole, IActivismEvent } from "@resistance-tech/api";
import React, { useCallback, useState } from "react";
import { useSnackbar } from "notistack";
import { format } from "date-fns";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import { ActivismUserBadge } from "./activismUserBadge";
import {
    getEventCapacityString, getEventTimeRangeString, isFullEvent, isParticipatingEvent, isPastEvent,
} from "./utils";
import { GoogleMapsAutocomplete } from "./googleMaps/googleMapsAutocomplete";
import { DATE_FN_OPTIONS } from "../../commons";
import { EventDetailsParticipants } from "./eventDetailsParticipants";

export function EventDetails(props: {
    event: IActivismEvent;
    currentUserId: string;
    currentUserRoles: string[];
    onClose: () => void;
    onEdit: () => void;
    onSignUp: () => Promise<void>;
    onSignOff: () => Promise<void>;
}) {
    const {
        event, currentUserId, currentUserRoles, onClose, onEdit, onSignUp, onSignOff,
    } = props;
    const {
        title,
        location,
        address,
        lat,
        lng,
        description,
        startTime,
        numberOfParticipants,
        participantUserProfiles,
        creatorUserProfile,
        createdTime,
        coordinatorUserProfile,
    } = event;
    const canSignUp = !isPastEvent(event) && !isFullEvent(event) && !isParticipatingEvent(event, currentUserId);
    const canSignOff = !isPastEvent(event) && isParticipatingEvent(event, currentUserId);
    const canEdit = event.creatorUserId === currentUserId
        || currentUserRoles.includes(FirestoreAdminUserRole.ActivismAdmin);
    const [isWorking, setIsWorking] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const knownNumberOfParticipants = (participantUserProfiles ?? []).length;

    const handleWorkingClick = useCallback(async (callback: () => Promise<void>, successMessage: string) => {
        try {
            setIsWorking(true);
            await callback();
            enqueueSnackbar(successMessage, { variant: "success" });
        } catch (e) {
            console.error(e);
            enqueueSnackbar("Hiba történt: kérjük próbáld újra!", { variant: "error" });
        } finally {
            setIsWorking(false);
        }
    }, [enqueueSnackbar]);

    const handleSignUpClick = useCallback(async () => {
        handleWorkingClick(onSignUp, "Sikeresen mentettük a jelentkezésed!");
    }, [onSignUp, handleWorkingClick]);

    const handleSignOffClick = useCallback(async () => {
        handleWorkingClick(onSignOff, "Sikeresen mentettük a lemondásod!");
    }, [onSignOff, handleWorkingClick]);

    return (
        <Grid container direction="column" spacing={5}>
            <Grid item>
                <Typography variant="h4">
                    {title}
                </Typography>
            </Grid>
            <Grid item container direction="column" spacing={2}>
                <Grid item>
                    <Typography variant="body1" component="div">
                        <strong>Időpont:</strong>
                        {" "}
                        {format(startTime, "yyyy. MMMM d.,", DATE_FN_OPTIONS)}
                        {" "}
                        {getEventTimeRangeString(event)}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1" component="div">
                        <strong>Helyszín:</strong>
                        {" "}
                        {location}
                    </Typography>
                </Grid>
                {address !== undefined && (
                    <Grid item>
                        <Typography variant="body1" component="div">
                            <strong>Cím:</strong>
                            {" "}
                            {address}
                        </Typography>
                    </Grid>
                )}
                {lat !== undefined && lng !== undefined && (
                    <Grid item>
                        <GoogleMapsAutocomplete
                            disableInput
                            value={lat === undefined || lng === undefined ? undefined : {
                                name: location,
                                lat,
                                lng,
                            }}
                        />
                    </Grid>
                )}
                <Grid item>
                    <Typography variant="body1" component="div">
                        <strong>Résztvevők száma:</strong>
                        {" "}
                        {getEventCapacityString(event)}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1" component="div">
                        <EventDetailsParticipants
                            event={event}
                            currentUserId={currentUserId}
                            currentUserRoles={currentUserRoles}
                        />
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1" component="div">
                        <strong>Koordinátor:</strong>
                        {" "}
                        <ActivismUserBadge userProfile={coordinatorUserProfile} />
                    </Typography>
                </Grid>
            </Grid>
            {description && (
                <Grid item>
                    <Typography variant="body1" component="div" sx={{ overflowWrap: "anywhere" }}>
                        {description}
                    </Typography>
                </Grid>
            )}
            <Grid item container direction="column">
                <Grid item>
                    <Typography variant="caption">
                        <strong>Az eseményt létrehozta:</strong>
                        {" "}
                        <ActivismUserBadge userProfile={creatorUserProfile} />
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="caption">
                        <strong>Az esemény létrejöttének időpontja:</strong>
                        {" "}
                        {format(createdTime, "yyyy. MMMM d., HH:mm", DATE_FN_OPTIONS)}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={onClose}
                        disabled={isWorking}
                    >
                        Bezár
                    </Button>
                    {canEdit && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onEdit}
                            startIcon={<EditIcon />}
                        >
                            Szerkesztés
                        </Button>
                    )}
                    {canSignUp && (
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            onClick={handleSignUpClick}
                            loading={isWorking}
                            startIcon={<CheckCircleIcon />}
                        >
                            Jelentkezem
                        </LoadingButton>
                    )}
                    {canSignOff && (
                        <LoadingButton
                            variant="contained"
                            color="secondary"
                            onClick={handleSignOffClick}
                            loading={isWorking}
                            startIcon={<CancelIcon />}
                        >
                            Lemondom
                        </LoadingButton>
                    )}
                </DialogActions>
            </Grid>
        </Grid>
    );
}
