import React, { Dispatch } from "react";
import { assertNever } from "../../utils/general";
import {
    ActionType, ActivismState, IComponentState,
} from "./activism.types";

export const DEFAULT_STATE : IComponentState = {
    events: [],
    userProfiles: [],
    organiserUserProfiles: [],
    isLoading: false,
    openEventId: undefined,
    isCreateEventOpen: false,
    editedEventId: undefined,
    filter: {
        searchTerm: "",
        onlyParticipating: false,
        onlyCoordinating: false,
        onlyOrganising: false,
        distanceOriginPoint: undefined,
        distanceKms: 1,
    },
};

export const ComponentStateContext = React.createContext<{
    state: IComponentState,
    dispatch: Dispatch<ActionType>
}>({ state: DEFAULT_STATE, dispatch: () => {} });

export const reducer = (originalState : IComponentState, action: ActionType) : IComponentState => {
    switch (action.type) {
    case ActivismState.ACTIVISM_SET_EVENTS:
        return { ...originalState, events: action.payload.events };
    case ActivismState.ACTIVISM_ADD_EVENTS:
        const eventsAfterAddition = originalState.events;
        action.payload.events.forEach((updatedEvent) => {
            const originalEventIndex = eventsAfterAddition.findIndex((event) => event.id === updatedEvent.id);
            if (originalEventIndex === -1) {
                eventsAfterAddition.push(updatedEvent);
            } else {
                eventsAfterAddition[originalEventIndex] = updatedEvent;
            }
        });
        return {
            ...originalState,
            events: eventsAfterAddition,
        };
    case ActivismState.ACTIVISM_REMOVE_EVENTS:
        const eventsAfterRemoval = originalState.events.filter((event) => !action.payload.eventIds.includes(event.id));
        return {
            ...originalState,
            events: eventsAfterRemoval,
        };
    case ActivismState.ACTIVISM_SET_IS_LOADING:
        return { ...originalState, isLoading: action.payload.isLoading };
    case ActivismState.ACTIVISM_SET_USER_PROFILES:
        return {
            ...originalState,
            userProfiles: action.payload.userProfiles,
            organiserUserProfiles: action.payload.organiserUserProfiles,
        };
    case ActivismState.ACTIVISM_OPEN_EVENT_VIEWER:
        return { ...originalState, openEventId: action.payload.eventId };
    case ActivismState.ACTIVISM_CLOSE_EVENT_VIEWER:
        return { ...originalState, openEventId: undefined };
    case ActivismState.ACTIVISM_OPEN_EVENT_CREATOR:
        return { ...originalState, isCreateEventOpen: true };
    case ActivismState.ACTIVISM_CLOSE_EVENT_CREATOR:
        return { ...originalState, isCreateEventOpen: false };
    case ActivismState.ACTIVISM_OPEN_EVENT_EDITOR:
        return { ...originalState, editedEventId: action.payload.eventId };
    case ActivismState.ACTIVISM_CLOSE_EVENT_EDITOR:
        return { ...originalState, editedEventId: undefined };
    case ActivismState.ACTIVISM_SET_FILTER:
        return { ...originalState, filter: action.payload.filter };
    default:
        assertNever(action, "Unexpected action type");
        return originalState;
    }
};
