import "firebaseui/dist/firebaseui.css";

import { GoogleAuthProvider, User, getAuth } from "firebase/auth";
import * as firebaseui from "firebaseui";
import { Store } from "redoodle";
import { FirebaseApp } from "firebase/app";
import { SetCurrentUser } from "../store/actions";
import { IAppState } from "../store/state";
import { NavUtils, Page } from "../utils/navUtils";
import { FirebaseAnalyticsService } from "./firebaseAnalyticsService";

export class FirebaseAuthUiService {
    private firebaseAuthUi: any;

    private defaultFirebaseAuthUiConfig = {
        signInSuccessUrl: NavUtils.getNavUrl[Page.Home](),
        signInOptions: [GoogleAuthProvider.PROVIDER_ID],
        tosUrl: "/terms-of-service",
        privacyPolicyUrl: "/privacy-policy",
        callbacks: {
            signInSuccessWithAuthResult: (authResult: any) => {
                this.setUser(authResult.user);
                this.analyticsService.logLogin({ method: "Google" });
                // Do not redirect.
                return true;
            },
        },
    };

    public constructor(
        firebaseApp: FirebaseApp,
        private analyticsService: FirebaseAnalyticsService,
        private store: Store<IAppState> | undefined,
    ) {
        const firebaseAuth = getAuth(firebaseApp);
        this.firebaseAuthUi = firebaseui === undefined ? undefined : new firebaseui.auth.AuthUI(firebaseAuth);
    }

    public authStart = (element: string | Element, signInSuccessUrl: string | undefined) => {
        this.firebaseAuthUi.start(element, this.getFirebaseAuthUiConfig(signInSuccessUrl));
    };

    private setUser = (user: User | null) => {
        const userOrUndefined = user === null ? undefined : user;
        if (this.store !== undefined) {
            this.store.dispatch(SetCurrentUser.create({ currentUser: userOrUndefined }));
        }
    };

    private getFirebaseAuthUiConfig = (signInSuccessUrl?: string) => (
        signInSuccessUrl === undefined ? this.defaultFirebaseAuthUiConfig : {
            ...this.defaultFirebaseAuthUiConfig,
            signInSuccessUrl,
        }
    );
}
