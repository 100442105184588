import { serverTimestamp } from "firebase/firestore";
import * as React from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { getGlobalServices } from "../../services/services";
import {
    selectCurrentUser,
    selectPost,
    selectSenders,
} from "../../store/selectors";
import { IAppState } from "../../store/state";
import { EditorRequest } from "../../types";

interface IProps {
    request: EditorRequest | undefined;
    onClose: () => void;
}

export function PostEditor({ request, onClose }: IProps) {
    const isOpen = request !== undefined;
    const postId = request?.type === "update" ? request?.itemId : undefined;

    // Redux state
    const user = useSelector((state: IAppState) => selectCurrentUser(state));
    const senders = useSelector((state: IAppState) => Object.entries(selectSenders(state)));

    const currentPost = useSelector((state: IAppState) => selectPost(state, postId ?? ""));

    // Local state
    const [title, setTitle] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [url, setUrl] = React.useState("");
    const [image, setImage] = React.useState("");
    const [senderId, setSenderId] = React.useState("");

    const [errors, setErrors] = React.useState<{ [property: string]: string | undefined }>({});

    React.useEffect(() => {
        if (isOpen && currentPost) {
            if (currentPost.title) {
                setTitle(currentPost.title);
            }

            if (currentPost.description) {
                setDescription(currentPost.description);
            }

            if (currentPost.url) {
                setUrl(currentPost.url);
            }

            if (currentPost.image) {
                setImage(currentPost.image);
            }

            if (currentPost.senderId) {
                setSenderId(currentPost.senderId);
            }
        }
    }, [isOpen, currentPost]);

    React.useEffect(() => {
        if (!senderId && senders.length) {
            setSenderId(senders[0][0]);
        }
    }, [senderId, senders]);

    const handleClose = () => {
        onClose();

        setTitle("");
        setDescription("");
        setUrl("");
        setSenderId("");
        setErrors({});
    };

    const handleDialogClose = (_event: {}, reason: "backdropClick" | "escapeKeyDown") => {
        if (reason === "backdropClick") {
            return;
        }
        handleClose();
    };

    const handleSaveClick = async () => {
        const globalServices = getGlobalServices();
        if (globalServices === undefined) {
            return;
        }

        const newErrors = {
            title: !title ? "Please give a title for your post" : undefined,
            senderId: !senderId ? "Please select a sender" : undefined,
        };
        setErrors(newErrors);

        if (Object.values(newErrors).some((error) => error)) {
            return;
        }

        handleClose();

        const postProps = {
            title,
            description: description || null,
            url,
            image,
            senderId,
        };

        const { dataService } = globalServices;
        if (request?.type === "update") {
            await dataService.updatePost(request.itemId, postProps);
        } else if (user !== undefined) {
            await dataService.createPost({
                ...postProps,
                createdTimestamp: serverTimestamp(),
                creatorUserId: user.uid,
            });
        }
    };

    const dialogTitle = currentPost ? "Edit post" : "Create post";

    return (
        <EditorDialog
            open={isOpen}
            onClose={handleDialogClose}
            aria-labelledby="form-dialog-title"
            classes={{ paper: "paper" }}
        >
            <EditorDialogtitle id="form-dialog-title">
                {dialogTitle}
            </EditorDialogtitle>

            <EditorDialogContent>
                <TextField
                    variant="outlined"
                    value={title}
                    onChange={({ target }) => setTitle(target.value)}
                    error={!!errors.title}
                    helperText={errors.title}
                    id="title"
                    label="Title"
                    margin="normal"
                    required
                    autoFocus
                    fullWidth
                />
                <TextField
                    variant="outlined"
                    value={description}
                    onChange={({ target }) => setDescription(target.value)}
                    error={!!errors.description}
                    helperText={errors.description}
                    id="description"
                    label="Description"
                    margin="normal"
                    multiline
                    fullWidth
                />
                <TextField
                    variant="outlined"
                    type="url"
                    value={url}
                    onChange={({ target }) => setUrl(target.value)}
                    error={!!errors.url}
                    helperText={errors.url}
                    id="url"
                    label="URL"
                    margin="normal"
                    fullWidth
                />
                <TextField
                    variant="outlined"
                    type="url"
                    value={image}
                    onChange={({ target }) => setImage(target.value)}
                    error={!!errors.image}
                    helperText={errors.image}
                    id="image"
                    label="Image (URL)"
                    margin="normal"
                    fullWidth
                />

                <SelectorsGrid container justifyContent="space-between">
                    <FormControl variant="outlined" error={!!errors.senderId} required>
                        <InputLabel id="sender-select-label">Sender</InputLabel>
                        <Select
                            value={senderId}
                            onChange={({ target }) => setSenderId(target.value as string)}
                            labelId="sender-select-label"
                            label="Sender"
                        >
                            {senders.map(([currentSenderId, sender]) => (
                                <MenuItem
                                    value={currentSenderId}
                                    key={currentSenderId}
                                >
                                    <Selector>
                                        <SenderPictureContainer>
                                            <SenderImage
                                                src={sender.imageUrl}
                                                className="sender-picture"
                                                alt="sender"
                                            />
                                        </SenderPictureContainer>

                                        {sender.name}
                                    </Selector>
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{errors.senderId}</FormHelperText>
                    </FormControl>
                </SelectorsGrid>
            </EditorDialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant="contained">
                    Cancel
                </Button>

                <Button
                    onClick={handleSaveClick}
                    variant="contained"
                    color="primary"
                >
                    Save &amp; Publish
                </Button>
            </DialogActions>
        </EditorDialog>
    );
}

// Styles
const EditorDialog = styled(Dialog)`
    .paper {
        padding: ${({ theme }) => theme.spacing(5)};
        padding-bottom:  ${({ theme }) => theme.spacing(7)};
    }
`;

const EditorDialogtitle = styled(DialogTitle)`
    padding-bottom:  ${({ theme }) => theme.spacing(5)};
    font-size: 1.3rem;
    font-weight: 500;
`;

const EditorDialogContent = styled(DialogContent)`
    padding-right: ${({ theme }) => theme.spacing(3)};
    margin-bottom: ${({ theme }) => theme.spacing(5)};

    & > * {
        margin-bottom: ${({ theme }) => theme.spacing(3)};
    }
`;

const SelectorsGrid = styled(Grid)`
    min-width: 10rem;
    margin-top: ${({ theme }) => theme.spacing(5)};

    & > * {
        flex-grow: 1;

        :first-of-type {
            max-width: 15rem;
            margin-right: ${({ theme }) => theme.spacing(5)};
        }
    }
`;

const Selector = styled("div")`
    display: flex;
    align-items: center;
`;

const SenderPictureContainer = styled("div")`
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    border-radius: 50%;
    overflow: hidden;
`;

const SenderImage = styled("img")`
    width: 1.5rem;
`;
