import React, { useState } from "react";
import { TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { InputChangeHandler } from "./input.types";

const useStyles = makeStyles(() => ({
    container: {
        width: "100%",
    },
}));

const validateEmail = (value: string) => /^(?:[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*)?$/.test(value);

export function EmailInput({ initialValue = "", inputChangeHandler = () => {} }
    : {initialValue? : string, inputChangeHandler?: InputChangeHandler<string>}) {
    const [value, setValue] = useState(initialValue);
    const [isValid, setValid] = useState(true);

    const classes = useStyles();
    return (
        <TextField
            className={classes.container}
            autoComplete="off"
            error={!isValid}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onBlur={() => {
                inputChangeHandler(value);
                setValid(validateEmail(value));
            }}
        />
    );
}
