import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import { styled } from "@mui/material/styles";
import {
    AppBar,
    Avatar,
    Button,
    Icon,
    IconButton,
    ListItemText,
    Menu,
    MenuItem,
    Snackbar,
    SnackbarContent,
    Toolbar,
    Tooltip,
    Typography,
} from "@mui/material";
import { amber, green } from "@mui/material/colors";
import { Close, Email } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useCallback } from "react";
import { FirestoreAdminUserRole, assertNever } from "@resistance-tech/api";
import environmentConfig from "../../environment/environmentConfig.json";
import { getGlobalServices } from "../../services/services";
import { selectCurrentUser, selectCurrentUserAdminUserConfig, selectHasPendingWrites } from "../../store/selectors";
import { IAppState } from "../../store/state";
import { usePrevious } from "../../utils/hooks";
import { NavUtils, Page } from "../../utils/navUtils";
import { UserProfileEditor } from "./userProfileEditor";
import { OpenUserProfileDialog } from "../../store/actions";

export function AppHeader(props: { appType: "resistance" | "activism" | "sheets" }) {
    const { appType } = props;

    const [isSignedOutMessageOpen, setSignedOutMessageOpen] = React.useState(false);
    const [isSaveSuccessfulMessageOpen, setSaveSuccessfulMessageOpen] = React.useState(false);

    const [userMenuAnchorEl, setUserMenuAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const isUserMenuOpen = Boolean(userMenuAnchorEl);
    const isMobileMenuOpen = Boolean(mobileMenuAnchorEl);
    const handleUserMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setUserMenuAnchorEl(event.currentTarget);
    };
    const handleUserMenuClose = useCallback(() => {
        setUserMenuAnchorEl(null);
    }, [setUserMenuAnchorEl]);
    const handleMobileMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMobileMenuAnchorEl(event.currentTarget);
    };
    const handleMobileMenuClose = () => {
        setMobileMenuAnchorEl(null);
    };

    const currentUser = useSelector((state: IAppState) => selectCurrentUser(state));
    const currentUserAdminConfig = useSelector((state: IAppState) => selectCurrentUserAdminUserConfig(state));
    const currentUserRoles = currentUserAdminConfig?.roles ?? [];
    const hasPendingWrites = useSelector((state: IAppState) => selectHasPendingWrites(state));
    const hasPendingWritesPrevious = usePrevious(hasPendingWrites, false);
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const location = useLocation();

    React.useEffect(() => {
        if (hasPendingWritesPrevious && !hasPendingWrites) {
            setSaveSuccessfulMessageOpen(true);
        }
    }, [hasPendingWritesPrevious, hasPendingWrites]);

    let feedbackUrl: string;
    switch (appType) {
    case "activism":
        feedbackUrl = "mailto:aktivistarendszer@momentum.hu?subject=aktivista.momentum.hu vélemény/kérdés";
        break;
    case "sheets":
        feedbackUrl = "mailto:adatbevitel@momentum.hu?subject=adatbevitel.momentum.hu vélemény/kérdés";
        break;
    case "resistance":
        feedbackUrl = "mailto:tech@momentum.hu?subject=Resistance Manager vélemény/kérdés";
        break;
    default:
        assertNever(appType, `Unexpected app type: ${appType}`);
    }
    const isLoggedIn = currentUser !== undefined;

    const handleSignOutClick = async () => {
        const globalServices = getGlobalServices();
        if (globalServices === undefined) {
            return;
        }

        await globalServices.firebaseAuthService.authSignOut();
        setSignedOutMessageOpen(true);
    };

    const handleOpenUserProfileEditorClick = useCallback(() => {
        dispatch(OpenUserProfileDialog.create());
        handleUserMenuClose();
    }, [dispatch, handleUserMenuClose]);

    const photoUrl = currentUser?.photoURL;
    const displayName = currentUser?.displayName;

    let avatar;
    if (photoUrl || (!photoUrl && !displayName)) {
        avatar = <AvatarImage src={photoUrl ?? ""} />;
    } else {
        const initials = displayName
            ?.split(" ")
            ?.filter((nameComponent) => nameComponent.length > 0)
            ?.map((nameComponent) => nameComponent[0])
            ?.join("");

        avatar = <AvatarImage>{initials}</AvatarImage>;
    }

    return (
        <MainAppBar position="static" elevation={0}>
            <Toolbar>
                <AppTitle variant="h6">
                    <AppTitleLink to={NavUtils.getNavUrl[Page.Home]()}>
                        {environmentConfig.orgName}
                    </AppTitleLink>
                </AppTitle>

                <AppIcons>
                    {appType === "resistance" && (
                        <>
                            <Button
                                color="inherit"
                                size="small"
                                onClick={handleMobileMenuClick}
                                endIcon={<KeyboardArrowDownIcon />}
                            >
                                Mobilapp
                            </Button>

                            <Menu
                                open={isMobileMenuOpen}
                                onClose={handleMobileMenuClose}
                                anchorEl={mobileMenuAnchorEl}
                            >
                                <MenuItem onClick={handleMobileMenuClose}>
                                    <IconLink to={NavUtils.getNavUrl[Page.MobilePosts]()}>
                                        <ListItemText primary="Posts" />
                                    </IconLink>
                                </MenuItem>
                                <MenuItem onClick={handleMobileMenuClose}>
                                    <IconLink to={NavUtils.getNavUrl[Page.MobileSurveys]()}>
                                        <ListItemText primary="Surveys" />
                                    </IconLink>
                                </MenuItem>
                                <MenuItem onClick={handleMobileMenuClose}>
                                    <IconLink to={NavUtils.getNavUrl[Page.MobileEvents]()}>
                                        <ListItemText primary="Events" />
                                    </IconLink>
                                </MenuItem>
                                <MenuItem onClick={handleMobileMenuClose}>
                                    <IconLink to={NavUtils.getNavUrl[Page.MobileTips]()}>
                                        <ListItemText primary="Tips" />
                                    </IconLink>
                                </MenuItem>
                            </Menu>
                        </>
                    )}

                    {appType === "resistance" && (
                        <IconLink to={NavUtils.getNavUrl[Page.JobsOverview]()}>
                            <Button color="inherit" size="small">
                                Jobs
                            </Button>
                        </IconLink>
                    )}

                    {(appType === "sheets" || appType === "resistance") && (
                        <>
                            <IconLink to={NavUtils.getNavUrl[Page.SheetTyping]()}>
                                <Button color="inherit" size="small">
                                    Ívek bevitele
                                </Button>
                            </IconLink>
                            <IconLink to={NavUtils.getNavUrl[Page.SheetUpload]()}>
                                <Button color="inherit" size="small">
                                    Feltöltés
                                </Button>
                            </IconLink>
                            <IconLink to={NavUtils.getNavUrl[Page.SheetStatistics]()}>
                                <Button color="inherit" size="small">
                                    Statisztika
                                </Button>
                            </IconLink>
                            <IconLink to={NavUtils.getNavUrl[Page.SheetSchemas]()}>
                                <Button color="inherit" size="small">
                                    Kampányok
                                </Button>
                            </IconLink>
                        </>
                    )}

                    {(appType === "activism" || appType === "resistance") && (
                        <>
                            <IconLink to={NavUtils.getNavUrl[Page.ActivismBooking]()}>
                                <Button color="inherit" size="small">
                                    Események
                                </Button>
                            </IconLink>
                        </>
                    )}

                    {currentUserRoles.includes(FirestoreAdminUserRole.Admin) && (
                        <IconLink to={NavUtils.getNavUrl[Page.Admin]()}>
                            <Button color="inherit" size="small">
                                Jogosultságok
                            </Button>
                        </IconLink>
                    )}

                </AppIcons>

                <Tooltip title="Hibajelentés / visszajelzés">
                    <FeedbackButton onClick={() => window.open(feedbackUrl, "_blank")}>
                        <Icon><Email /></Icon>
                    </FeedbackButton>
                </Tooltip>

                {isLoggedIn ? (
                    <>
                        <AvatarButton
                            className="app-header-avatar-button"
                            onClick={handleUserMenuClick}
                            disableRipple
                        >
                            {avatar}
                        </AvatarButton>

                        <Menu
                            open={isUserMenuOpen}
                            onClose={handleUserMenuClose}
                            anchorEl={userMenuAnchorEl}
                        >
                            <MenuItem onClick={handleSignOutClick}>
                                <ListItemText primary="Kijelentkezés" />
                            </MenuItem>
                            <MenuItem onClick={handleOpenUserProfileEditorClick}>
                                <ListItemText primary="Profil" />
                            </MenuItem>
                        </Menu>
                    </>
                ) : null}

                {!isLoggedIn ? (
                    <Button
                        color="inherit"
                        size="small"
                        className="app-header-sign-in-button"
                        onClick={() => NavUtils.singInAndReturn(navigate, location)}
                    >
                        Bejelentkezés
                    </Button>
                ) : null}

                <Snackbar
                    autoHideDuration={3000}
                    anchorOrigin={{ horizontal: "center", vertical: "top" }}
                    message={<span>You&apos;ve successfully signed out</span>}
                    onClose={() => setSignedOutMessageOpen(false)}
                    open={isSignedOutMessageOpen}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={() => setSignedOutMessageOpen(false)}
                            size="large"
                        >
                            <Close />
                        </IconButton>,
                    ]}
                />

                <Snackbar
                    anchorOrigin={{ horizontal: "right", vertical: "top" }}
                    open={hasPendingWrites}
                >
                    <SnackbarContent
                        message={<span>Saving... (go online if you&apos;re not)</span>}
                        style={{ backgroundColor: amber[900] }}
                    />
                </Snackbar>

                <Snackbar
                    autoHideDuration={3000}
                    anchorOrigin={{ horizontal: "right", vertical: "top" }}
                    open={isSaveSuccessfulMessageOpen}
                    onClose={() => setSaveSuccessfulMessageOpen(false)}
                >
                    <SnackbarContent
                        message={<span>Save succeeded</span>}
                        style={{ backgroundColor: green[700] }}
                    />
                </Snackbar>

                <UserProfileEditor />
            </Toolbar>
        </MainAppBar>
    );
}

// Styles
const MainAppBar = styled(AppBar)`
    padding: ${({ theme }) => theme.spacing(0, 5)};
`;

const AppTitle = styled(Typography)`
    margin-right: ${({ theme }) => theme.spacing(10)};
`;

const AppTitleLink = styled(Link)`
    color: inherit;
`;

const AppIcons = styled("div")`
    flex-grow: 1;
`;

const IconLink = styled(Link)`
    margin-left: 5px;
    margin-right: 5px;
    color: inherit;
`;

const FeedbackButton = styled(IconButton)`
    color: inherit;
`;

const AvatarButton = styled(IconButton)`
    margin-left: ${({ theme }) => theme.spacing(3)};
`;

const AvatarImage = styled(Avatar)`
    width: 30px;
    height: 30px;
`;
