import {
    IAddress, IFieldType, IFormSchema, IFormSubmission, IName, ISignatureData, ISignatureDataWithImportFailureReason,
} from "@resistance-tech/api";

export enum SheetState {
    SHEET_ID_CHANGED,
    SIGNATURE_CHANGED,
    SIGNATURE_ADDED,
    SHEET_REQUESTED,
    SHEET_ASSIGNED,
    SHEET_COMPLETED,
    SHEET_INVALID,
    SHEET_RESET,
    SHEET_REPORTED,
    SHEET_ARCHIVED,
}

export interface SheetIdChangedAction {
    type: typeof SheetState.SHEET_ID_CHANGED;
    payload: string;
}

export interface SignatureChangedAction<T> {
    type: typeof SheetState.SIGNATURE_CHANGED;
    payload: {
        index: number;
        field: IFieldType;
        value: T | undefined;
    }
}

export interface SignatureAddedAction {
    type: typeof SheetState.SIGNATURE_ADDED;
}

export interface SheetRequested {
    type: typeof SheetState.SHEET_REQUESTED;
}

export interface SheetAssigned {
    type: typeof SheetState.SHEET_ASSIGNED;
    payload: {
        assignedSheetId: string,
        schema?: IFormSchema,
        imageUrl?: string,
        previousSubmission?: Pick<IFormSubmission<ISignatureDataWithImportFailureReason>, "data" | "sheetId">,
    };
}

export interface SheetCompleted {
    type: typeof SheetState.SHEET_COMPLETED;
}

export interface SheetInvalid {
    type: typeof SheetState.SHEET_INVALID;
    payload: {message: string}
}

export interface SheetReported {
    type: typeof SheetState.SHEET_REPORTED;
}

export interface SheetArchived {
    type: typeof SheetState.SHEET_ARCHIVED;
}

export interface SheetReset {
    type: typeof SheetState.SHEET_RESET;
}

export type ActionType = SheetIdChangedAction
    | SignatureAddedAction
    | SignatureChangedAction<string | boolean | IAddress | IName>
    | SheetRequested
    | SheetAssigned
    | SheetCompleted
    | SheetInvalid
    | SheetReset
    | SheetReported
    | SheetArchived;

export interface IComponentState {
    schema?: IFormSchema;
    submission: IFormSubmission<ISignatureData>;
    imageUrl?: string;
    errorMessage?: string;
    requestInProgress: boolean;
    action: "complete" | "report" | "archive" | undefined

}
