import * as React from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import { getGlobalServices } from "../../services/services";
import { selectPosts } from "../../store/selectors";
import { IAppState } from "../../store/state";
import { EditorRequest } from "../../types";
import { DeleteDialog } from "./deleteDialog";
import { PostEditor } from "./postEditor";
import { PostView } from "./postView";

export function PostManager() {
    // Redux state
    const posts = useSelector((state: IAppState) => selectPosts(state));

    // Local state
    const [editingRequest, setEditingRequest] = React.useState<EditorRequest | undefined>(undefined);
    const [deletingPostId, setDeletingPostId] = React.useState<string | undefined>(undefined);

    const handleDeleteDialogClose = (isConfirmed: boolean) => {
        if (isConfirmed) {
            const dataService = getGlobalServices()?.dataService;

            if (dataService && deletingPostId) {
                dataService.deletePost(deletingPostId);
            }
        }

        setDeletingPostId(undefined);
    };

    return (
        <ManagerContainer>
            <PostEditor
                request={editingRequest}
                onClose={() => setEditingRequest(undefined)}
            />

            <DeleteDialog
                itemId={deletingPostId}
                itemType="post"
                onClose={handleDeleteDialogClose}
            />

            <CreateButton
                onClick={() => setEditingRequest({ type: "create" })}
                startIcon={<Add />}
                variant="contained"
                color="primary"
                aria-label="Create post"
            >
                Create post
            </CreateButton>

            <PostCards>
                {posts.map(([id, post]) => (
                    <PostView
                        key={id}
                        post={post}
                        onEditButtonClick={() => setEditingRequest({ type: "update", itemId: id })}
                        onDeleteButtonClick={() => setDeletingPostId(id)}
                    />
                ))}
            </PostCards>
        </ManagerContainer>
    );
}

// Styles
const ManagerContainer = styled("div")`
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    overflow-y: auto;
`;

const CreateButton = styled(Button)`
    margin: ${({ theme }) => theme.spacing(5, 0)};
`;

const PostCards = styled("div")`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;
    max-width: 45rem;
    width: 100%;
    margin: auto;
`;
