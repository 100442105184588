import { FirebaseApp } from "firebase/app";
import {
    getAnalytics, logEvent, setUserId, setUserProperties,
} from "firebase/analytics";

export class FirebaseAnalyticsService {
    private analytics;

    public constructor(firebaseApp: FirebaseApp) {
        this.analytics = getAnalytics(firebaseApp);
        setUserProperties(this.analytics, { isResistanceUser: "true" });
    }

    public setUserId = (userId: string | undefined) => {
        // Set as user property
        setUserProperties(this.analytics, { firebaseUserId: userId ?? null });
        // Set also as analytics user ID
        if (userId === undefined) {
            // We can't unset the user ID on analytics
            return;
        }
        setUserId(this.analytics, userId);
    }

    public logLogin = (props: { method: "Google"; }) => {
        logEvent(this.analytics, "login", props);
    }

    public logPageView = () => {
        logEvent(this.analytics, "page_view", {
            // page_title: document.title, // not sure if the title updates before this is called
            page_location: window.location.href,
            page_path: window.location.pathname,
        });
    }
}
