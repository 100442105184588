import React, { useState } from "react";
import { TextField } from "@mui/material";
import { InputChangeHandler } from "./input.types";

export function PhoneInput({ initialValue = "", inputChangeHandler = () => {} }
    : {initialValue? : string, inputChangeHandler?: InputChangeHandler<string>}) {
    const [value, setValue] = useState(initialValue);

    return (
        <TextField
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onBlur={() => inputChangeHandler(value)}
            placeholder="0036 70 1234567"
        />
    );
}
