import { Button, Card, Grid } from "@mui/material";
import React, { useCallback, useContext } from "react";
import AddIcon from "@mui/icons-material/Add";
import { FirestoreAdminUserRole } from "@resistance-tech/api";
import { useSelector } from "react-redux";
import { ActivismState } from "./activism.types";
import { ComponentStateContext } from "./activism.state";
import { selectCurrentUserAdminUserConfig } from "../../store/selectors";
import { ActivismFilters } from "./activismFilters";
import { PhoneNumberAddButton } from "./phoneNumberAddButton";

export function ActivismActionBar() {
    const { dispatch } = useContext(ComponentStateContext);
    const userAdmin = useSelector(selectCurrentUserAdminUserConfig);
    const roles = userAdmin?.roles ?? [];

    const handleAddEventClick = useCallback(() => {
        dispatch({ type: ActivismState.ACTIVISM_OPEN_EVENT_CREATOR });
    }, [dispatch]);

    const isPhoneNumberMissing = (userAdmin?.phoneNumber ?? "") === "";
    const isAddAllowed = roles.includes(FirestoreAdminUserRole.ActivismAdmin)
        || roles.includes(FirestoreAdminUserRole.ActivismOrganiser);

    return (
        <Card sx={{ margin: (theme) => theme.spacing(2) }}>
            <Grid
                container
                spacing={3}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                flexWrap="nowrap"
                sx={{ padding: (theme) => theme.spacing(3) }}
            >
                <Grid item>
                    <ActivismFilters />
                </Grid>
                {isPhoneNumberMissing && (
                    <Grid item flexShrink={0}>
                        <PhoneNumberAddButton />
                    </Grid>
                )}
                {isAddAllowed && (
                    <Grid item flexShrink={0}>
                        <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddEventClick}>Új esemény</Button>
                    </Grid>
                )}
            </Grid>
        </Card>
    );
}
