import * as React from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import {
    IFirestoreSurveyOptionsChoice,
    IFirestoreSurveyOptionsScale, IFirestoreSurveyOptionsText,
    SurveyOptions,
    SurveyType,
} from "@resistance-tech/api";

interface IProps {
    options: SurveyOptions
}

export function SurveyOptionsView({ options }: IProps) {
    return (
        <div>
            <hr />
            <Type>{`Survey Type: ${options.type}` ?? "-"}</Type>
            <SurveyParams options={options} />
        </div>
    );
}

function SurveyParams({ options }: IProps) {
    switch (options.type) {
    case SurveyType.Scale:
        return <SurveyScale type={options.type} from={options.from} to={options.to} />;
    case SurveyType.Choice:
        return (
            <SurveyChoice
                type={options.type}
                isMultiSelect={options.isMultiSelect}
                values={options.values}
            />
        );
    case SurveyType.Text:
        return <SurveyText type={options.type} maxLength={options.maxLength} />;
    default:
        // TODO (tsvetlin): assertNever did not work.
        console.error(`Unexpected survey type: ${options.type}`);
        return null;
    }
}

function SurveyScale({ from, to }: IFirestoreSurveyOptionsScale) {
    return (
        <div>
            <Element>{`From: ${from}`}</Element>
            <Element>{`To: ${to}`}</Element>
        </div>
    );
}

function SurveyChoice({ isMultiSelect, values }: IFirestoreSurveyOptionsChoice) {
    return (
        <div>
            <Element>{`Multiple choice ${isMultiSelect ? "is" : "not"} allowed`}</Element>
            <Element>
                Possible answers
                {values.map((value) => (
                    <ListElement>
                        {`${value.key + 1}. ${value.label}`}
                    </ListElement>
                ))}
            </Element>
        </div>
    );
}

function SurveyText({ maxLength }: IFirestoreSurveyOptionsText) {
    return (
        <div>
            <Element>{`Answers maximum length: ${maxLength ?? "unlimited"}`}</Element>
        </div>
    );
}

const Type = styled(Typography)`
  font-style: italic;
  margin-top: ${({ theme }) => theme.spacing(5)};
` as typeof Typography;

const Element = styled(Typography)`
` as typeof Typography;

const ListElement = styled(Typography)`
  padding-left: ${({ theme }) => theme.spacing(5)};
` as typeof Typography;
