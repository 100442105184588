import * as React from "react";
import { styled } from "@mui/material/styles";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Typography,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { IFirestoreEvent } from "@resistance-tech/api";

interface IProps {
    event: IFirestoreEvent;
    onEdit: () => void;
    onDelete: () => void;
}

export function EventView({ event, onEdit, onDelete }: IProps) {
    const {
        title, date, description, fbEventUrl, imageUrl,
    } = event;

    const eventDate = date.toDate();

    return (
        <EventCard>
            <CardContent>
                <Title variant="h6" gutterBottom>{title}</Title>

                <Date variant="subtitle1" color="textSecondary" gutterBottom>
                    {eventDate.toLocaleString()}
                </Date>

                <Description component="p">
                    <b>Description:</b>
                    <br />
                    {description ?? "-"}
                </Description>

                <Description component="p">
                    <b>Event Image:</b>
                    <br />
                    {imageUrl ? <Image src={imageUrl} alt="Event" /> : "-"}
                </Description>

                <Description component="p">
                    <b>Facebook Event URL:</b>
                    <br />
                    {fbEventUrl ? <a href={fbEventUrl} target="_blank" rel="noopener noreferrer">{fbEventUrl}</a> : "-"}
                </Description>

            </CardContent>

            <EventCardActions>
                <Button onClick={onEdit} startIcon={<Edit />}>
                    Edit
                </Button>

                <Button onClick={onDelete} startIcon={<Delete />}>
                    Delete
                </Button>
            </EventCardActions>
        </EventCard>
    );
}

// Styles
const EventCard = styled(Card)`
  margin: 20px 0 0 0;
  padding: ${({ theme }) => theme.spacing(2, 4)};
`;

const EventCardActions = styled(CardActions)`
  justify-content: flex-end;

  & > * {
    padding-left: ${({ theme }) => theme.spacing(5)};
    padding-right: ${({ theme }) => theme.spacing(5)};

    :not(:first-of-type) {
      margin-left: ${({ theme }) => theme.spacing(3)};
    }
  }
`;

const Title = styled(Typography)`
  font-weight: 500;

  & > a {
    color: black;
  }
`;

const Date = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(5)};
`;

const Description = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(5)};
  word-break: break-all;
` as typeof Typography;

const Image = styled("img")`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
`;
