import React, { useEffect, useState } from "react";
import { Checkbox } from "@mui/material";
import { InputChangeHandler } from "./input.types";

export function ConsentInput({ initialValue = false, inputChangeHandler = () => {} }
    : {initialValue? : boolean, inputChangeHandler?: InputChangeHandler<boolean>}) {
    const [isConsent, setConsent] = useState(initialValue);

    useEffect(() => {
        inputChangeHandler(isConsent);
    }, [isConsent, inputChangeHandler]);

    return (
        <Checkbox
            value={isConsent}
            checked={isConsent}
            onChange={(e) => setConsent(e.target.checked)}
        />
    );
}
