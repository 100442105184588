import React, { useEffect, useState } from "react";
import MaterialTable from "@material-table/core";
import {
    Button, CircularProgress, Tooltip,
} from "@mui/material";

import ThreeSixtyIcon from "@mui/icons-material/ThreeSixty";

import { CollectionId, IFormSchema } from "@resistance-tech/api";
import { SchemaFormDialog } from "./schemaFormDialog";

import { getGlobalServices } from "../../../services/services";

function StatusUpdateButton({ schema, onUpdated } : {schema: IFormSchema, onUpdated: (schema: IFormSchema) => void}) {
    const [isUpdating, setUpdating] = useState(false);
    const functionService = getGlobalServices()?.functionsService;
    if (!functionService) {
        return (<></>);
    }

    if (isUpdating) {
        return <CircularProgress />;
    }

    const handleStatusChange = async () => {
        const { name, status } = schema;
        const newStatus = status === "inactive" ? "active" : "inactive";
        setUpdating(true);
        try {
            await functionService.updateSchema({ schemaId: name, status: newStatus });
            onUpdated(schema);
        } finally {
            setUpdating(false);
        }
    };

    return (
        <Tooltip title={schema.status === "inactive" ? "aktivál" : "inaktivál"}>
            <Button disabled={isUpdating} onClick={handleStatusChange}>
                <ThreeSixtyIcon />
            </Button>
        </Tooltip>
    );
}

export function SchemaList() {
    const [schemas, setSchemas] = useState<IFormSchema[]>([]);
    const [isLoading, setLoading] = useState(false);
    const [isDialogActive, setDialogActive] = useState(false);
    const [selectedSchema, setSelectedSchema] = useState<IFormSchema | null>(null);
    const [reload, setReload] = useState(true);

    const dataService = getGlobalServices()?.dataService;
    const functionService = getGlobalServices()?.functionsService;

    useEffect(() => {
        if (!dataService) {
            return;
        }

        if (reload) {
            setLoading(true);
            dataService
                .getAllDocuments<IFormSchema>(CollectionId.SheetsSchemas)
                .then(async (schemaObject: {[name: string]: IFormSchema}) => {
                    const schemaArray = Object.entries(schemaObject).map(([name, schema]) => ({ ...schema, name }));
                    setSchemas(schemaArray);
                    setLoading(false);
                    setReload(false);
                });
        }
    }, [setLoading, setSchemas, dataService, reload, setReload]);

    if (isLoading || !functionService) {
        return (<CircularProgress />);
    }

    return (
        <>
            <MaterialTable
                title="Kampányok"
                columns={[
                    { title: "Kampány név", field: "name" },
                    { title: "Oszlopok", field: "fields", render: ({ fields }) => <span>{ fields.join(", ") }</span> },
                    { title: "Sorok száma", field: "defaultNumberOfRows" },
                    { title: "Nation Builder Teg-ek", field: "nationBuilderTags", render: ({ nationBuilderTags }) => <span>{ nationBuilderTags.join(", ") }</span> },
                    {
                        title: "Állapot",
                        field: "status",
                        render: (schema) => (
                            <div>
                                <span>{schema.status === "inactive" ? "inaktív" : "aktív"}</span>
                                <StatusUpdateButton schema={schema} onUpdated={() => setReload(true)} />
                            </div>
                        ),
                    },
                ]}
                actions={[
                    {
                        icon: "add",
                        tooltip: "Másolás",
                        onClick: (_, schema) => {
                            if (Array.isArray(schema)) {
                                return;
                            }
                            setSelectedSchema(schema);
                            setDialogActive(true);
                        },
                    },
                    {
                        icon: "add",
                        tooltip: "Új kampány",
                        isFreeAction: true,
                        onClick: () => {
                            setSelectedSchema(null);
                            setDialogActive(true);
                        },
                    },
                ]}
                options={
                    {
                        rowStyle: ({ status }) => (status === "inactive" ? { color: "grey" } : {}),
                    }
                }
                data={schemas}
            />
            <SchemaFormDialog
                initialSchema={selectedSchema}
                open={isDialogActive}
                onClose={(isSave) => {
                    setDialogActive(false);
                    if (isSave) {
                        setReload(true);
                    }
                }}
            />
        </>
    );
}
